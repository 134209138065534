type ST = typeof setTimeout;
type P = Parameters<ST>;

/**
 * Same thing as setTimeout, except if the time is zero,
 * it just immediately invokes the function
 *
 * @param func
 * @param time
 */
/* eslint consistent-return: 0 */
const smartSetTimeout = (func: P[0], time: P[1]) => {
	if (time && time !== 0) {
		const timeout = setTimeout(func, time);
		return timeout;
	}
	func();
};

export default smartSetTimeout;
