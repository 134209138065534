import axios from 'axios';
import { getContentFilePath, getStimulusListFilePath, getExpConfigFilePath } from 'utility';
import { ExperimentSettings } from 'types';

export const getExperimentSettings = async (experimentName: string) => {
	const response = await axios.get<ExperimentSettings>(getExpConfigFilePath(experimentName));
	return response.data;
};

export const getExperimentContent = async (experimentName: string) => {
	const response = await axios.get<string>(getContentFilePath(experimentName));
	return response.data;
};

export const getExperimentStimulusList = async (experimentName: string, source: string) => {
	const response = await axios.get<string>(getStimulusListFilePath(experimentName, source));
	return response.data;
};
