import React, { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { defaults } from 'utility';
import * as Pages from './pages';

const GlobalStyles = createGlobalStyle`
/*
	input[type='text']:not(.browser-default):focus:not([readonly]) {
		border-bottom-color: ${defaults.theme.inputColor};
		box-shadow: 0 1px 0 0 ${defaults.theme.inputColor};
	}
*/
`;

const App: FC = () => (
	<>
		<GlobalStyles />
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Pages.Home />} />
				<Route path='/experiment/:name' element={<Pages.Experiment />} />
			</Routes>
		</BrowserRouter>
	</>
);

export default App;
