/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC } from 'react';
import { ConsentStage } from 'types';
import { Button, Header } from 'ui';
import { Content } from 'components';
import { useExperimentContext } from 'context';
import { defaults } from 'utility';

/**
 * Handles a Consent Stage
 */

export const ConsentHandler: FC = () => {
	const { goToNextStage, currentStage } = useExperimentContext();
	const stage = currentStage as ConsentStage;
	const [showNextButtons, setShowNextButtons] = useState(false);

	// a function to run on every render
	useEffect(() => {
		const listener = (event: any) => {
			if (event.code === 'ArrowLeft') {
				userAgreed();
			} else if (event.code === 'ArrowRight') {
				goToNextStage(true);
			}
		};

		setTimeout(() => {
			document.addEventListener('keydown', listener);
			setShowNextButtons(true);
		}, 0);

		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	// run if the user click "Agree" or "ArrowLeft" on the keyboard
	const userAgreed = () => {
		goToNextStage();
	};

	return (
		<>
			<div
				style={{
					textAlign: 'center'
				}}>
				<Header >{stage.title}</Header>
			</div>
			<div
				style={{
					backgroundColor: '#ffffff',
					padding: '0px',
					height: '50%',
					overflowY: 'hidden',
					overflowX: 'hidden',
					justifyContent:'center'
				}}
				data-testid={'consent-form-container'}
				onScroll={() => {
					setShowNextButtons(true);
				}}
			>
						<Content contentID={stage.content} defaultTo={defaults.consent.consentText} />
				<div
				style={{
					width: '600px',
					marginLeft: 'auto',
					marginRight: 'auto'
				}}>
					<br />
					<h5>User consent</h5>
					<p>If you agree to participate, click the green button</p>
					<p>Otherwise click the red button</p>
					<br />
				</div>
			</div>
			{showNextButtons ? (
				<div
					style={{ textAlign: 'center', justifyContent: 'space-around', display: 'flex' }}
					data-testid={'consent-buttons-container'}
				>
					<Button color='green' onClick={userAgreed}>
						I Agree
					</Button>
					<Button color='red' onClick={() => goToNextStage(true)}>
						I Disagree
					</Button>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
