/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC } from 'react';
import { ConclusionStage} from 'types';
import { Content } from 'components';
import { Header } from 'ui';
import { useExperimentContext } from 'context';
import { generateParticipantCode, SaveData } from 'utility';

/**
 * Handles a Conclusion Stage
 */
export const ConclusionHandler: FC = () => {
	const {
		experimentResults,
		experimentStartTime,
		stimulusData,
		stimulusHeader,
		experimentName,
		pcodeurl,
		experimentStartDateTime,
		collectionName,
		fileNameColumn,
		currentStage,
		stimListName
	} = useExperimentContext();

	const stage = currentStage as ConclusionStage;

	const [saveDone, setSaveDone] = useState<Boolean>(false);

	useEffect(() => {
		const stim = stimulusData == null ? [] : stimulusData;
		const stimHeader = stimulusHeader == null ? [] : stimulusHeader;
		const experimentFinalName = collectionName ?? experimentName;
		const experimentEndTime = performance.now();
		const experimentEndDateTime = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
		experimentResults.experimentDuration = experimentEndTime - experimentStartTime;
		if (experimentResults.pcode === undefined) {
			if (pcodeurl === undefined || pcodeurl == null) {
				experimentResults.pcode = generateParticipantCode();
			} else {
				experimentResults.pcode = pcodeurl;
			}
		}
		const dataSaver = new SaveData({
			exp_data: experimentResults,
			stim_data: stim,
			stim_header: stimHeader,
			stim_list: stimListName!,
			experiment_name: experimentFinalName!,
			experiment_start_time: experimentStartDateTime,
			experiment_end_time: experimentEndDateTime,
			experiment_file_name_column: fileNameColumn!
		});

		dataSaver.output_to_db().then(() => setSaveDone(true));
	}, []);

	return (
		<>
			<Header>{stage.title}</Header>
			<Content contentID={stage.content} />
			{/* Not showing the finish button until saving has finished gives more flexibility for error handling */}
			<div style={{visibility: 'hidden'}}>
				{(saveDone || !stage.mature) && (
					setTimeout(function () {
						window.location.href = stage.followUpLink + "?pcode="+experimentResults.pcode;
						}, stage.followUpDelay)
					)
				} 
			</div>
		</>
	);
};
