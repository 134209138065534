import { useTrialRunnerContext } from 'context';
import { FC } from 'react';
import styled from 'styled-components';

interface UIProps {
	numCorrect: number;
	numIncorrect: number;
	numNotResponded: number;
}

const ScoreboardUI = styled.div<UIProps & { numTotal: number }>`
	display: flex;
	margin-bottom: 15px;
	height: 7px;

	.numCorrect {
		background-color: green;
		width: ${(props) => Math.floor((props.numCorrect / props.numTotal) * 100)}%;
	}

	.numIncorrect {
		background-color: red;
		width: ${(props) => Math.floor((props.numIncorrect / props.numTotal) * 100)}%;
	}

	.numNotResponded {
		background-color: yellow;
		width: ${(props) => Math.floor((props.numNotResponded / props.numTotal) * 100)}%;
	}
`;

export const Scoreboard: FC = () => {
	const { numCorrect, numIncorrect, numNotResponded } = useTrialRunnerContext();

	return (
		<ScoreboardUI
			numCorrect={numCorrect}
			numIncorrect={numIncorrect}
			numNotResponded={numNotResponded}
			numTotal={numCorrect + numIncorrect + numNotResponded}
		>
			<div className='numCorrect' />
			<div className='numIncorrect' />
			<div className='numNotResponded' />
		</ScoreboardUI>
	);
};
