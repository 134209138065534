import { useExperimentContext } from 'context';
import {  FC, useCallback, useEffect, useState } from 'react';
import { Button, Header } from 'ui';
import { wait } from 'utility';
type PhaseState = 'informTest' | 'informStereo' | 'testing' | 'passed' | 'tryAgain' | 'failure';
export const StereoTest: FC = () => {
	const { goToNextStage, srcToAudioMap } = useExperimentContext();
	const [currentPhase, setCurrentPhase] = useState<PhaseState>('informStereo');
	const [timesFailed, setTimesFailed] = useState(0);
	const playNoise = async (sound: string) => {
		const audio = srcToAudioMap.get("$" + sound) as HTMLAudioElement;
		await audio.play();
		await wait(800);
	};

	const generatePattern = function():string[]{
		let key:string[] = ["first","second","third"];
		let pattern:string[] = [];
		pattern.push(key[ Math.floor(Math.random() * 3)]!);
		pattern.push(key[ Math.floor(Math.random() * 3)]!);
		pattern.push(key[ Math.floor(Math.random() * 3)]!);
		return pattern;
	}
	const [stimulusPattern] = useState<string[]>(generatePattern());

	useEffect(() => {
		if (timesFailed > 0 && timesFailed < 2) {
			setCurrentPhase('tryAgain');
		} else if (timesFailed >= 2) {
			setCurrentPhase('failure');
		}
	}, [timesFailed]);

	switch (currentPhase) {
		case 'informTest': {
			return <InformTestPhase playNoise={playNoise} setCurrentPhase={setCurrentPhase} />;
		}
		case 'informStereo': {
			return <InformStereoPhase playNoise={playNoise} setCurrentPhase={setCurrentPhase} />;
		}
		case 'testing': {
			return (
				<TestingPhase
					playNoise={playNoise}
					setCurrentPhase={setCurrentPhase}
					setTimesFailed={setTimesFailed}
					stimulusPattern={stimulusPattern}
				/>
			);
		}
		case 'tryAgain': {
			return (
				<>
					<Header>Stereo Test Failed</Header>
					<Button onClick={() => setCurrentPhase('testing')}>Try again</Button>
				</>
			);
		}
		case 'failure': {
 			return (
                <>
					{/* <Header>Too Many Failures</Header> */}
					<Header>Too Many Failures</Header>
					<br />		
					<div style={{visibility: 'hidden'}}>			
						{(setTimeout(() => goToNextStage(true), 2500))}
					</div>
				</>
			);
		}
		case 'passed': {
			return (
				<>
					<Header>Stereo Test Passed!</Header>
					<br />
					<Button onClick={() => goToNextStage()}>Next</Button>
				</>
			);
		}
		default: {
			throw new Error('Unhandled stereo test phase');
		}
	}
};
interface PhaseParameters {
	playNoise: (sound: string) => Promise<void>;
	setCurrentPhase: React.Dispatch<React.SetStateAction<PhaseState>>;
};
function InformStereoPhase({ playNoise, setCurrentPhase }: PhaseParameters) {
	const [buttonsClicked, setButtonsClicked] = useState([false, false]);

	return (
		<>
			<Header>Stereo Test</Header>
            <h5>
			We need to make sure you can hear in stereo. This is a requirement of the experiment.<br/><br/>
			 
				<strong>Click each arrow. </strong>
				You should hear a short beep on the left when you click the left-pointing arrow and on the right when you click right-pointing arrow. <br/><br/>
			
			Reverse your headphones or earbuds if you don't.
            </h5>
			<br/>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '63%' }}>
				<div
					data-ear='left'
					className='arrow-icon arrow-ear-test fade-in-transition'
					style={{ cursor: 'pointer' }}
					data-testid='leftear-test'
					onClick={async () => {
						await playNoise('lefttone');
						setButtonsClicked((buttonsClicked) => [true, buttonsClicked[1] as boolean]);
					}}
				>
					<i className='fas fa-arrow-circle-left fa-3x' />
				</div>
				<div
					data-ear='right'
					className='arrow-icon arrow-ear-test fade-in-transition'
					style={{ cursor: 'pointer' }}
					data-testid='rightear-test'
					onClick={async () => {
						await playNoise('righttone');
						setButtonsClicked((buttonsClicked) => [buttonsClicked[0] as boolean, true]);
					}}
				>
					<i className='fas fa-arrow-circle-right fa-3x' />
				</div>
			</div>
			<br />
			{buttonsClicked.every((buttonClicked) => buttonClicked) ? (
				<Button color='green' onClick={(e) => setCurrentPhase('informTest')}>
					Start Test
				</Button>
			) : (
				<></>
			)}
		</>
	);
}
function InformTestPhase({ playNoise, setCurrentPhase }: PhaseParameters) {
	const [buttonsClicked, setButtonsClicked] = useState(false);
return(
	<>
		<Header>Stereo Test</Header>
            <h5>
				You will hear three noises played one after the other. Along with one of the noises there will also be a faint beep. Click the number that corresponds to the noise that contained the beep.<br/>
            </h5>
			<br/>
			<div style={{ display: 'flex', justifyContent: 'space-between', width: '20%' }}>
			<Button style={{width: 'fit-content'}} onClick={async () => {
				await playNoise('first');
				setButtonsClicked((buttonsClicked) => true);
				}}>
				Hear an example
			</Button>
			</div>
			<div style={{paddingTop: "5%"}}>
			{buttonsClicked ? (
				<Button color='green' onClick={(e) => setCurrentPhase('testing')}>
					Start Test
				</Button>
			) : (
				<></>
			)}
			</div>

	</>
)}

interface TestingPhaseParameters extends PhaseParameters {
	setTimesFailed: React.Dispatch<React.SetStateAction<number>>;
	stimulusPattern: string[];
}

function TestingPhase({ playNoise, setCurrentPhase, setTimesFailed, stimulusPattern }: TestingPhaseParameters) {
	const [testResults, setTestResults] = useState<boolean[]>([]);
	const [currentStimulusIndex, setCurrentStimulusIndex] = useState(0);

	const presentSound = useCallback(
		async (testIndex) => {
			if (testIndex === 0) {
				await wait(500);
			} else {
				await wait(500);
			}
			await playNoise(stimulusPattern[testIndex]!);
		},
		[playNoise, stimulusPattern]
	);

	useEffect(() => {
		if (currentStimulusIndex < stimulusPattern.length) {
			presentSound(currentStimulusIndex);
		}
	}, [currentStimulusIndex, presentSound, stimulusPattern.length]);

	useEffect(() => {
		if (testResults.length === stimulusPattern.length) {
			if (testResults.every((testResult) => testResult)) {
				setCurrentPhase('passed');
			} else {
				setTimesFailed((prevTimes) => prevTimes + 1);
			}
		}
	}, [setCurrentPhase, setTimesFailed, stimulusPattern.length, testResults]);
	return(
		<>
	<h2 style={{ textAlign: 'center'}}>Which noise contains a beep?</h2>
			<br />
			<div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around',width: '100%' }}>
				<Button style={{width: '150px', height: "150px", fontSize: '75px'}}
					onClick={async () => {
						setTestResults((testResults) => [
							...testResults,
							stimulusPattern[currentStimulusIndex] === 'first'
						]);
						setCurrentStimulusIndex((currentStimulusIndex) => currentStimulusIndex + 1);
					}}
				>
					1
				</Button>
				<Button style={{width: '150px', height: "150px", fontSize: '75px'}}
					onClick={() => {
						setTestResults((testResults) => [
							...testResults,
							stimulusPattern[currentStimulusIndex] === 'second'
						]);
						setCurrentStimulusIndex((currentStimulusIndex) => currentStimulusIndex + 1);
					}}
				>
					2
				</Button>
				<Button style={{width: '150px', height: "150px", fontSize: '75px'}}
					onClick={() => {
						setTestResults((testResults) => [
							...testResults,
							stimulusPattern[currentStimulusIndex] === 'third'
						]);
						setCurrentStimulusIndex((currentStimulusIndex) => currentStimulusIndex + 1);
					}}
				>
					3
				</Button>
			</div>
		</>
	)
}



// import React, { FC, useCallback, useEffect, useState } from 'react';
// import { Spinner } from 'components';
// import { wait } from 'utility';
// import { Button, Header } from 'ui';
// import { useExperimentContext } from 'context';

// const stimulusData: Ear[] = new Array(3).fill(0).map((_) => (Math.random() > 0.5 ? 'left' : 'right'));

// type PhaseState = 'inform' | 'testing' | 'passed' | 'tryAgain' | 'failure';
// export type Ear = 'left' | 'right';

// interface StereoTestProps {
// 	stimulusDataOverride?: Ear[];
// }

// /**
//  * Runs a Stereo Test
//  */
// export const StereoTest: FC<StereoTestProps> = ({ stimulusDataOverride }: StereoTestProps) => {
// 	const { goToNextStage, srcToAudioMap } = useExperimentContext();
// 	const [currentPhase, setCurrentPhase] = useState<PhaseState>('inform');
// 	const [timesFailed, setTimesFailed] = useState(0);
// 	const [stimulusPattern] = useState<Ear[]>(stimulusDataOverride ?? stimulusData);

// 	const playNoise = async (ear: Ear) => {
// 		const audio = srcToAudioMap.get(`$${ear}noise`) as HTMLAudioElement;
// 		await audio.play();
// 		await wait(800);
// 	};

// 	useEffect(() => {
// 		if (timesFailed > 0 && timesFailed < 2) {
// 			setCurrentPhase('tryAgain');
// 		} else if (timesFailed >= 2) {
// 			setCurrentPhase('failure');
// 		}
// 	}, [timesFailed]);

// 	switch (currentPhase) {
// 		case 'inform': {
// 			return <InformPhase playNoise={playNoise} setCurrentPhase={setCurrentPhase} />;
// 		}
// 		case 'testing': {
// 			return (
// 				<TestingPhase
// 					playNoise={playNoise}
// 					setCurrentPhase={setCurrentPhase}
// 					setTimesFailed={setTimesFailed}
// 					stimulusPattern={stimulusPattern}
// 				/>
// 			);
// 		}
// 		case 'tryAgain': {
// 			return (
// 				<>
// 					<Header>Stereo Test Failed</Header>
// 					<Button onClick={() => setCurrentPhase('testing')}>Try again</Button>
// 				</>
// 			);
// 		}
// 		case 'failure': {
//  			return (
//                 <>
// 					{/* <Header>Too Many Failures</Header> */}
// 					<Header>Too Many Failures</Header>
// 					<br />		
// 					<div style={{visibility: 'hidden'}}>			
// 						{(setTimeout(() => goToNextStage(true), 2500))}
// 					</div>
// 				</>
// 			);
// 		}
// 		case 'passed': {
// 			return (
// 				<>
// 					<Header>Stereo Test Passed!</Header>
// 					<br />
// 					<Button onClick={() => goToNextStage()}>Next</Button>
// 				</>
// 			);
// 		}
// 		default: {
// 			throw new Error('Unhandled stereo test phase');
// 		}
// 	}
// };

// interface PhaseParameters {
// 	playNoise: (ear: Ear) => Promise<void>;
// 	setCurrentPhase: React.Dispatch<React.SetStateAction<PhaseState>>;
// }

// function InformPhase({ playNoise, setCurrentPhase }: PhaseParameters) {
	// const [buttonsClicked, setButtonsClicked] = useState([false, false]);

	// return (
	// 	<>
	// 		<Header>Stereo Test</Header>
    //         <h5>
	// 		We need to make sure you can hear in stereo. This is a requirement of the experiment.<br/><br/>
			 
	// 			<strong>Click each arrow. </strong>
	// 			You should hear a short beep on the left when you click the left-pointing arrow, and on the right when you click the right-pointing arrow. <br/><br/>
			
	// 		Reverse your headphones or earbuds if you don't.
    //         </h5>
	// 		<br/>
	// 		<div style={{ display: 'flex', justifyContent: 'space-between', width: '63%' }}>
	// 			<div
	// 				data-ear='left'
	// 				className='arrow-icon arrow-ear-test fade-in-transition'
	// 				style={{ cursor: 'pointer' }}
	// 				data-testid='leftear-test'
	// 				onClick={async () => {
	// 					await playNoise('left');
	// 					setButtonsClicked((buttonsClicked) => [true, buttonsClicked[1] as boolean]);
	// 				}}
	// 			>
	// 				<i className='fas fa-arrow-circle-left fa-3x' />
	// 			</div>
	// 			<div
	// 				data-ear='right'
	// 				className='arrow-icon arrow-ear-test fade-in-transition'
	// 				style={{ cursor: 'pointer' }}
	// 				data-testid='rightear-test'
	// 				onClick={async () => {
	// 					await playNoise('right');
	// 					setButtonsClicked((buttonsClicked) => [buttonsClicked[0] as boolean, true]);
	// 				}}
	// 			>
	// 				<i className='fas fa-arrow-circle-right fa-3x' />
	// 			</div>
	// 		</div>
	// 		<br />
	// 		{buttonsClicked.every((buttonClicked) => buttonClicked) ? (
	// 			<Button color='green' onClick={(e) => setCurrentPhase('testing')}>
	// 				Start Test
	// 			</Button>
	// 		) : (
	// 			<></>
	// 		)}
	// 	</>
	// );
// }

// interface TestingPhaseParameters extends PhaseParameters {
// 	setTimesFailed: React.Dispatch<React.SetStateAction<number>>;
// 	stimulusPattern: Ear[];
// }

// function TestingPhase({ playNoise, setCurrentPhase, setTimesFailed, stimulusPattern }: TestingPhaseParameters) {
// 	const [waitingForInput, setWaitingForInput] = useState(false);
// 	const [testResults, setTestResults] = useState<boolean[]>([]);
// 	const [currentStimulusIndex, setCurrentStimulusIndex] = useState(0);

// 	const presentSound = useCallback(
// 		async (testIndex) => {
// 			if (testIndex === 0) {
// 				await wait(500);
// 			} else {
// 				await wait(500);
// 			}

// 			if (stimulusPattern[testIndex] === 'left') {
// 				await playNoise('left');
// 				setWaitingForInput(true);
// 			} else {
// 				await playNoise('right');
// 				setWaitingForInput(true);
// 			}
// 		},
// 		[playNoise, stimulusPattern]
// 	);

// 	useEffect(() => {
// 		if (currentStimulusIndex < stimulusPattern.length) {
// 			presentSound(currentStimulusIndex);
// 		}
// 	}, [currentStimulusIndex, presentSound, stimulusPattern.length]);

// 	useEffect(() => {
// 		if (testResults.length === stimulusPattern.length) {
// 			if (testResults.every((testResult) => testResult)) {
// 				setCurrentPhase('passed');
// 			} else {
// 				setTimesFailed((prevTimes) => prevTimes + 1);
// 			}
// 		}
// 	}, [setCurrentPhase, setTimesFailed, stimulusPattern.length, testResults]);

// 	if (!stimulusPattern[currentStimulusIndex]) return <></>;
// 	if (!waitingForInput) {
// 		return (
// 			<div style={{ textAlign: 'center' }}>
// 				{/* <Spinner /> */}
// 			</div>
// 		);
// 	}

// 	return (
// 		<>
// 			<h2 style={{ textAlign: 'center' }}>Which Ear?</h2>
// 			<br />
// 			<div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
// 				<Button
// 					onClick={async () => {
// 						setTestResults((testResults) => [
// 							...testResults,
// 							stimulusPattern[currentStimulusIndex] === 'left'
// 						]);
// 						setWaitingForInput(false);
// 						setCurrentStimulusIndex((currentStimulusIndex) => currentStimulusIndex + 1);
// 					}}
// 				>
// 					Left
// 				</Button>
// 				<Button
// 					onClick={() => {
// 						setTestResults((testResults) => [
// 							...testResults,
// 							stimulusPattern[currentStimulusIndex] === 'right'
// 						]);
// 						setWaitingForInput(false);
// 						setCurrentStimulusIndex((currentStimulusIndex) => currentStimulusIndex + 1);
// 					}}
// 				>
// 					Right
// 				</Button>
// 			</div>
// 		</>
// 	);
// }
