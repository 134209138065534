import { FC } from 'react';
import { InformStage } from 'types';
import { NextButton, Content } from 'components';
import { Header } from 'ui';
import { useExperimentContext } from 'context';

interface Props {
	stageOverride?: InformStage;
	nextHandler?: () => void;
	breakNumber?: string;
	delay?: number;
}

/**
 * Handles an Inform Stage
 */
export const InformHandler: FC<Props> = ({ stageOverride, nextHandler, breakNumber, delay }) => {
	const { goToNextStage, currentStage } = useExperimentContext();
	const stage = stageOverride ?? (currentStage as InformStage);

	return (
		<>
			<Header>{stage.title}</Header>
			<Content contentID={stage.content} breakNumber={breakNumber} />
			<NextButton toDo={nextHandler ?? goToNextStage} delay={stage.nextButtonDelay} />
		</>
	);
};
