/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useMemo, createContext, FC } from 'react';
import { defaults, smartSetTimeout } from 'utility';
import { KeypressResponse, TypingResponse } from 'types';
import { useTrialRunnerContext } from './TrialRunnerContext';
import { useExperimentContext } from './ExperimentContext';

interface Props {}

const useValue = (props: Props) => {
	const { stimulusData, headerToIndexMap } = useExperimentContext();
	const [currentPresentationIndex] = useState(0);
	const {
		addTrialResponse,
		resetPresentationElements,
		setPause,
		endPhase,
		currentRow,
		incrementTrialIndex,
		currentStage,
		isFeedback,
		effectiveTrialIndex,
		incrementNumCorrect,
		incrementNumIncorrect,
		incrementNumNotResponded
	} = useTrialRunnerContext();
    // console.log(currentStage); 
	const { presentations } = currentStage;
	const presentation = presentations[currentPresentationIndex];
	const [currentResponseIndex, setCurrentResponseIndex] = useState(0);
	const [didntRespond, setDidntRespond] = useState(false);
	const [responded, setResponded] = useState(false);
	const startTime = useMemo(() => performance.now(), [currentResponseIndex]);
	const [feedback, setFeedback] = useState('');

	if (!currentRow || !stimulusData) throw new Error('No current row... How did we get here');

	const isCorrectAnswer = (candidateAnswer: string) => {
		const realAnswer = currentRow[
			headerToIndexMap?.get(
				(currentResponse as KeypressResponse | TypingResponse).answerColumn as string
			) as number
		] as string;

		return candidateAnswer === realAnswer;
	};
	function getRandomInt(max: number) {
		return Math.floor(Math.random() * max);
	}
	const goToNextTrial = (feedbackTime?: number) => {
		if (!currentStage.waitAfterTrial) throw new Error('No waitAfterTrial');
		const random = getRandomInt(currentStage.waitAfterTrial.length);
		setPause();
		resetPresentationElements();
		setResponded(true);
		incrementTrialIndex();
		let timeAfter = (currentStage.waitAfterTrial[random] ?? defaults.trialRunner.waitAfterTrial[0]) as number;
		timeAfter += feedbackTime!;
/*      console.log(timeAfter); */
		smartSetTimeout(() => endPhase(), timeAfter);
	};
	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}
	const goToNextResponse = async (text?: string, delay?: number, feedbackTime?: number) => {
		if (currentResponseIndex === currentStage.responses.length - 1) {
			if (text === 'keypress') {
				if (delay == null) {
					await timeout(250);
				} else {
					await timeout(delay);
				}
			}
			goToNextTrial(feedbackTime!);
		} else {
			setCurrentResponseIndex((currentResponseIndex) => currentResponseIndex + 1);
		}
	};

	const endResponseForcibly = () => {
		addTrialResponse('-1', performance.now() - startTime, null, effectiveTrialIndex);
		incrementNumNotResponded();
		setDidntRespond(true);
		goToNextResponse();
	};

	const addUserResponse = (answer: string, text?: string, delay?: number) => {
		// Get current correct answer
		let correctAnswer = '';
		if (
			presentation &&
			'showFeedbackType' in presentation &&
			presentation.showFeedbackType &&
			presentation.correctAnswer
		) {
			// @ts-ignore
			if (currentRow[headerToIndexMap.get(presentation.correctAnswer)]) {
				// @ts-ignore
				correctAnswer = currentRow[headerToIndexMap.get(presentation.correctAnswer)];
			}
		}

		// Add to response
		addTrialResponse(answer, performance.now() - startTime, null, effectiveTrialIndex, correctAnswer);

		let resAlert = '';
		// Compare user response time and suggest time range
		if (currentResponse && currentResponse.responseTimeRange != null) {
			const properTimeMin = currentResponse.responseTimeRange[0];
			const properTimeMax = currentResponse.responseTimeRange[1];
			const currentResponseTime = performance.now() - startTime;
			if (currentResponseTime < properTimeMin) {
				resAlert = 'Your response is too fast!';
			} else if (currentResponseTime > properTimeMax) {
				resAlert = 'Your response is too slow!';
			} else {
				resAlert = 'Good timing for your response!';
			}
		}

		let correctAlert = '';
		// Get current correct answer
		if (presentation && 'showFeedbackType' in presentation && presentation.showFeedbackType) {
			// Get showFeedbackType from the config file
			const { showFeedbackType } = presentation;

			// Compare user choice and correct answer to give a feed back
			if (
				(answer === correctAnswer && showFeedbackType === 'correct') ||
				(answer === correctAnswer && showFeedbackType === 'both')
			) {
				correctAlert = ' Your answer is correct!';
			} else if (
				(answer !== correctAnswer && showFeedbackType === 'incorrect') ||
				(answer !== correctAnswer && showFeedbackType === 'both')
			) {
				correctAlert = ' Your answer is incorrect!';
			}
		}
		// if(resAlert!==''||correctAlert!==''){
		// alert(resAlert+correctAlert)
		setFeedback(resAlert + correctAlert);
		// }

		if (isFeedback) {
			if (isCorrectAnswer(answer)) {
				incrementNumCorrect();
			} else {
				incrementNumIncorrect();
			}
		}

		let feedbackTime = 0;

		if (resAlert !== '' || correctAlert !== '') {
			if (presentation && 'feedbackTime' in presentation && presentation.feedbackTime != null) {
				feedbackTime = presentation.feedbackTime;
			} else {
				feedbackTime = defaults.trialRunner.presentation.text.feedbackTime;
			}
		}
	//	console.log(feedbackTime);
		setDidntRespond(false);
		goToNextResponse(text, delay, feedbackTime);
	};

	const addUserMicResponse = (answer: any, url: any) => {
		addTrialResponse(answer, performance.now() - startTime, url, effectiveTrialIndex);
		setDidntRespond(false);
		goToNextResponse();
	};

	// Get the current response
	const currentResponse = currentStage.responses[currentResponseIndex];

	return {
		currentResponseIndex,

		currentStage,
		currentResponse,
		startTime,
		didntRespond,
		responded,
		feedback,

		endResponseForcibly,
		addUserResponse,
		goToNextTrial,
		addUserMicResponse
	};
};

export const responsePhaseContext = createContext({} as ReturnType<typeof useValue>);

export const useResponsePhaseContext = () => useContext(responsePhaseContext);

export const ResponsePhaseContextProvider: FC<Props> = (props) => (
	<responsePhaseContext.Provider value={useValue(props)}>{props.children}</responsePhaseContext.Provider>
);
