/* eslint-disable react-hooks/exhaustive-deps */
import { ExperimentConfiguration } from 'models';
import { useState, createContext, FC, useContext, useCallback, useEffect, SetStateAction } from 'react';
import Papa from 'papaparse';
import {
	ExperimentResults,
	GoToNextStage,
	StimulusData,
	HeaderToIndexMap,
	SrcToAudioMap,
	BlockResult,
	TrialRunnerStage,
	Stage,
	AudioPresentation
} from 'types';
import { getAudioFilePath, getExpDirPath } from 'utility';
import { getExperimentContent, getExperimentSettings, getExperimentStimulusList } from './ExperimentAPI';
interface Props {
	experimentName: string | undefined;
	stimList?: string | null;
	pcode?: string | null;
}

const DEFAULT_STIMULI: string = 'stimuli';

const defaultSrcAudioMap: SrcToAudioMap = new Map<string, HTMLAudioElement>([
	['$spark', new Audio('/templates/audio/spark.mp3')],
	['$lefttone', new Audio('/templates/audio/lefttone.mp3')],
	['$righttone', new Audio('/templates/audio/righttone.mp3')],
	['$first', new Audio('/templates/audio/snd1.ogg')],
	['$second', new Audio('/templates/audio/snd2.ogg')],
	['$third', new Audio('/templates/audio/snd3.ogg')],
]);

const useValue = ({ experimentName, stimList, pcode }: Props) => {
	const [expConfig, setExpConfig] = useState<ExperimentConfiguration>();
	const [contentString, setContentString] = useState<string | null>();
	const [content, setContent] = useState<HTMLElement>();
	const [srcToAudioMap, setSrcToAudioMap] = useState<SrcToAudioMap>(defaultSrcAudioMap);
	const [currentStageIndex, setCurrentStageIndex] = useState<number>();
	const [collectionName, setCollectionName] = useState<string>();
	const [fileNameColumn, setFileNameColumn] = useState<string>();
	const [pcodeurl, setpcodeurl] = useState<string | null | undefined>(pcode);
	const [stimListName, setStimListName] = useState<string>();
	const [currentStage, setCurrentStage] = useState<Stage>();
	const [experimentStartTime,setExperimentTime] = useState(0);
	const [experimentStartDateTime] = useState(`${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`);
	const [stimulusHeader, setStimulusHeader] = useState<string[]>();
	const [stimulusData, setStimulusData] = useState<StimulusData>();
	const [headerToIndexMap, setHeaderToIndexMap] = useState<HeaderToIndexMap>();
	const [experimentResults, setExperimentResults] = useState({} as ExperimentResults);

	const getSettings = useCallback(async () => {
		try {
			const res = await getExperimentSettings(experimentName!);
			var defaultStimulis: string = DEFAULT_STIMULI
			if(typeof res.defaultStimulusList !== 'undefined'){
				defaultStimulis = res.defaultStimulusList;
			}
			console.log(res)
			console.log(defaultStimulis)
			setExpConfig(new ExperimentConfiguration(res, experimentName!, stimList ?? defaultStimulis));
		} catch (e) {
			console.log(e);
			throw new Error('No expConfig found');
		}
	}, [setExpConfig, experimentName, stimList]);

	const getContentFile = useCallback(async () => {
		try {
			const res = await getExperimentContent(experimentName!);
			setContentString(res);
		} catch {
			setContentString(null);
		}
	}, [setContentString, experimentName]);

	const processTrialRunnerConfiguration = useCallback(
		async (config: Stage | undefined) => {
			if (config) {
				const trialRunnerConfig = config as TrialRunnerStage;
				const res = await getExperimentStimulusList(experimentName!, trialRunnerConfig.stimuliSource!);
				const { data } = Papa.parse<string[]>(res);
				const headerRow: string[] = data.shift()!;
				setStimulusHeader(headerRow);
				setStimListName(trialRunnerConfig.stimuliSource);
				const blockedData: StimulusData = [];
				data.forEach((row) => {
					const blockNumberString = row[0] as string;
					const blockNumber = Number.parseInt(blockNumberString);
					const block = blockedData[blockNumber];

					if (!block) {
						blockedData[blockNumber] = [row];
					} else {
						block.push(row);
					}
				});
				setStimulusData(blockedData);

				const tempHeaderToIndexMap: HeaderToIndexMap = new Map();

				headerRow.forEach((columnHeader, columnIndex) => tempHeaderToIndexMap.set(columnHeader, columnIndex));

				setHeaderToIndexMap(tempHeaderToIndexMap);

				const audioPresentations = trialRunnerConfig.presentations.reduce((arr, presentation) => {
					if (presentation.type === 'audio') {
						arr.push(presentation);
					} else if (presentation.type === 'concurrent') {
						arr.push(
							...(presentation.presentations.filter(
								(pres) => pres.type === 'audio'
							) as AudioPresentation[])
						);
					}
					return arr;
				}, [] as AudioPresentation[]);

				// add each audio file to the audio map
				const tempSrcToAudioMap: SrcToAudioMap = new Map();
				audioPresentations.forEach((audioPresentation) => {
					data.forEach((row) => {
						const audioColumnIndex = tempHeaderToIndexMap.get(audioPresentation.fileNameColumn);
						if (!audioColumnIndex) throw new Error();

						const fileName = row[audioColumnIndex];
						if (fileName) {
							tempSrcToAudioMap.set(fileName, new Audio(getAudioFilePath(experimentName!, fileName)));
						}
					});
				});
				setSrcToAudioMap((prevSrcToAudioMap) => new Map([...prevSrcToAudioMap, ...tempSrcToAudioMap]));
			}
		},
		[experimentName]
	);

	useEffect(() => {
		getSettings();
		getContentFile();
	}, [getSettings, getContentFile]);

	useEffect(() => {
		setContent(new DOMParser().parseFromString(contentString ?? '', 'text/html').documentElement);
	}, [contentString]);

	useEffect(() => {
		if (expConfig) {
			setCurrentStageIndex(expConfig.firstStageIndex);
			setCurrentStage(expConfig.stages[expConfig.firstStageIndex]);
			setCollectionName(expConfig.collectionName);
			setFileNameColumn(expConfig.fileNameColumn);
			processTrialRunnerConfiguration(expConfig.stages.find((stage: Stage) => stage.type === 'trialRunner'));
		}
	}, [expConfig, processTrialRunnerConfiguration]);

	useEffect(() => {
		if (expConfig && currentStageIndex !== undefined) {
			const newStage = expConfig.stages[currentStageIndex];
			setCurrentStage(newStage);
		}
	}, [expConfig, currentStageIndex]);

	/**
	 * Determines what stage to go to next.
	 *
	 * @param goodOrBad Optional. If good, go to experiment nextStageIfYes. If bad, go to nextStageIfNo.
	 */
	const goToNextStage: GoToNextStage = useCallback(
		(endPrematurely: boolean = false) => {
			if (expConfig) {
				if (currentStageIndex! + 1 >= expConfig.stages.length) {
					// There are no more stages. Restarts the experiment right now.
					window.location.href = getExpDirPath(experimentName!);
				} else {
					// There are more stages
					setCurrentStageIndex((currentStageIndex) => {
						if (!endPrematurely) {
							// Just go to the next stage chronologically

							return currentStageIndex! + 1;
						} else {
							const answer = expConfig.stages.findIndex(
								// @ts-ignore
								(stage) => stage.name === (currentStage.nextStageIfNo ?? 'prematureConclusion')
							);

							if (answer === -1) throw new Error('No stage with name "prematureConclusion"');

							return answer;
						}
					});
				}
			}
		},
		[expConfig, currentStage, currentStageIndex, experimentName]
	);

	const addBlockResults = (blockResults: BlockResult[]) => {
		setExperimentResults((prevExperimentResults) => ({ ...prevExperimentResults, blockResults }));
	};

	const updateExpeimentDuration = function (duration: SetStateAction<number>){
		setExperimentTime(duration);
	};

	return {
		stimulusData,
		stimulusHeader,
		experimentName,
		collectionName,
		pcodeurl,
		setpcodeurl,
		currentStage: currentStage!,

		experimentResults,
		stimListName,
		currentStageIndex,

		goToNextStage,
		expConfig,
		headerToIndexMap,
		srcToAudioMap,
		content,

		addBlockResults,
		updateExpeimentDuration,

		experimentStartTime,
		experimentStartDateTime,
		fileNameColumn
	};
};

export const experimentContext = createContext({} as ReturnType<typeof useValue>);
export const useExperimentContext = () => useContext(experimentContext);

export const ExperimentContextProvider: FC<Props> = (props) => (
	<experimentContext.Provider value={useValue(props)}>{props.children}</experimentContext.Provider>
);
