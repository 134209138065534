import { FC } from 'react';

interface Props {
	text: string;
	x: number;
	y: number;
	fontSize: number | string;
}

export const TextPresentation: FC<Props> = ({ text, x, y, fontSize }) => (
	<h2
		style={{
			textAlign: 'left',
			marginTop: x >= 0 || y >= 0 ? '30px' : 0,
			position: x >= 0 || y >= 0 ? 'absolute' : 'static',
			top: y >= 0 ? `${(y * window.innerHeight) / 100}px` : 'auto',
			left: x >= 0 ? `${(x * window.innerWidth) / 100}px` : 'auto',
			fontSize,
			width: 'auto'
		}}
	>
		{text}
	</h2>
);
