/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC, HTMLProps } from 'react';
import { Button } from 'ui';
import { defaults } from 'utility';

interface Props extends HTMLProps<HTMLButtonElement> {
	toDo: () => void;
	delay?: number;
	buttonName?: string;
}

export const NextButton: FC<Props> = ({ toDo, buttonName, delay, style }) => {
	const [showNextButton, setShowNextButton] = useState(false);

	// Waits some time to set the next button
	useEffect(() => {
		setTimeout(() => {
			setShowNextButton(true);
		}, delay ?? defaults.global.nextButtonDelay);

		const listener = (event: any) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				toDo();
			}
		};

		const timeout = setTimeout(
			() => document.addEventListener('keydown', listener),
			delay ?? defaults.global.nextButtonDelay
		);

		return () => {
			clearTimeout(timeout);
			document.removeEventListener('keydown', listener);
		};
	}, []);

	return showNextButton ? (
		<Button style={style} onClick={() => toDo()}>
			{buttonName ?? 'Next'}
		</Button>
	) : (
		<></>
	);
};
