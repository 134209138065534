import { FC, useState, useRef, useEffect, useCallback } from 'react';
import { useExperimentContext } from 'context';
import { hsbToRgb } from 'utility';
import { Button, Header } from 'ui';

/**
 * Runs a Mic Test
 */
export const MicTest: FC = () => {
	const { goToNextStage } = useExperimentContext();
	const [volumeLevel, setVolumeLevel] = useState(0);
	const [showNextButton, setShowNextButton] = useState(false);
	const animationFrameID = useRef(0);
	const ctx = useRef<AudioContext>();

	// Sets up the microphone
	const setupMic = useCallback(async () => {
		const getMedia = async () => {
			try {
				return await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false
				});
			} catch (err) {
				throw new Error();
			}
		};

		const stream = await getMedia();

		ctx.current = new AudioContext();
		const mic = ctx.current.createMediaStreamSource(stream);
		const analyzer = ctx.current.createAnalyser();
		analyzer.fftSize = 256;
		mic.connect(analyzer);
		// analyzer.connect(ctx.current.destination)

		const loop = () => {
			animationFrameID.current = requestAnimationFrame(loop);

			const bufferLength = analyzer.frequencyBinCount;
			const dataArray = new Uint8Array(bufferLength);
			analyzer.getByteFrequencyData(dataArray);

			const value = dataArray.reduce((pers, cur) => pers + cur) / dataArray.length;

			setVolumeLevel(value);
		};

		loop();
	}, []);

	useEffect(() => {
		setupMic();
	}, [setupMic]);

	useEffect(() => {
		if (volumeLevel > 60) setShowNextButton(true);
	}, [volumeLevel]);

	return (
		<>
			<Header>Microphone Test</Header>
			<p>We need to ensure your mic will provide clear recordings</p>
			<li>Plug a microphone (earbuds with a mic are fine) into your computer and make sure it is on</li>
			<li>Position yourself so that the mic is near your mouth</li>
			<li>
				Speak the phrase <strong>This is a short sentence</strong> at a volume that you would normally speak to
				a friend
			</li>
			<li>
				Increase the mic level until you see the bar turn <strong style={{ color: 'red' }}>Red</strong> for most
				of the sentence
			</li>
			<br />
			<div style={{ outline: 'solid #fff 1px', height: '10px' }}>
				<div
					style={{
						background: `${(() => {
							const v2 = volumeLevel > 90 ? 90 : volumeLevel;
							const rgb = hsbToRgb({ h: 240 + (120 * v2) / 100, s: 100, b: 95 });

							return `rgb(${rgb.r},${rgb.b},${rgb.g})`;
						})()}`,
						width: `${volumeLevel > 75 ? 75 : volumeLevel}%`,
						height: '10px'
					}}
				/>
			</div>
			<br />
			<Button
				showIf={showNextButton}
				onClick={() => {
					ctx.current?.close();
					cancelAnimationFrame(animationFrameID.current);
					goToNextStage();
				}}
			>
				Next
			</Button>
		</>
	);
};
