import { defaults } from '.';

const EXAMPLE: boolean = process.env.REACT_APP_USE_EXAMPLE_EXPERIMENTS === 'true';

export const getRootDirPath = () => `/${defaults.trialRunner.experimentsDirname}`;

export const getExamplesRootDirPath = () => `/${defaults.trialRunner.exampleExperimentsDirname}`;

export const getExpDirPath = (experimentName: string) => {
	const def = `${getRootDirPath()}/${experimentName}`;
	const examples = `${getExamplesRootDirPath()}/${experimentName}`;
	if (EXAMPLE) {
		return examples;
	}
	return def;
};

export const getExpConfigFilePath = (experimentName: string) =>
	`${getExpDirPath(experimentName)}/${defaults.expConfigFileName}`;

export const getContentFilePath = (experimentName: string) => `${getExpDirPath(experimentName)}/content.html`;

export const getStimListDirPath = (experimentName: string) =>
	`${getExpDirPath(experimentName)}/${defaults.trialRunner.stimuliDirname}`;

export const getStimulusListFilePath = (experimentName: string, stimuliSource?: string) =>
	`${getStimListDirPath(experimentName)}/${stimuliSource ?? defaults.trialRunner.stimuliFilename}`;

export const getAudioDirPath = (experimentName: string) => `${getExpDirPath(experimentName)}/audio`;

export const getAudioFilePath = (experimentName: string, fileName: string) =>
	`${getAudioDirPath(experimentName)}/${fileName}`;

export const getImageDirPath = (experimentName: string) =>
	`${getExpDirPath(experimentName)}/${defaults.trialRunner.presentation.image.dirname}`;

export const getImageFilePath = (experimentName: string, fileName: string) =>
	`${getImageDirPath(experimentName)}/${fileName}`;
