import { FC } from 'react';
import { TrialRunnerContextProvider, useExperimentContext } from 'context';
import {
	IntroductionHandler,
	ConsentHandler,
	CalibrationHandler,
	InformHandler,
	TrialRunnerHandler,
	ConclusionHandler
} from './stages';

/**
 * Root of the experiment. Initializes data that will never be set again.
 */
export const ExperimentRunner: FC = () => {
	const { expConfig, content, headerToIndexMap, stimulusData, srcToAudioMap } = useExperimentContext();

	const isAvailable = () => {
		if (!(expConfig && expConfig.availability)) return true;

		const now = new Date();
		const startDate = new Date(expConfig.availability.startDate);
		const endDate = new Date(expConfig.availability.endDate);
		if (now > startDate && now < endDate) {
			return true;
		}
		return false;
	};

	if (expConfig && stimulusData && content && headerToIndexMap && srcToAudioMap) {
		return <>{isAvailable() ? <StageHandler /> : <h3>Experiment not available!</h3>}</>;
	}
	return <></>;
};

/**
 * Handles what the current stage type of the experiment is.
 */
const StageHandler: FC = () => {
	const { currentStage } = useExperimentContext();
	if (!currentStage) throw new Error('No stage found');

	// Determines what stage type this stage is.

	switch (currentStage.type) {
		case 'introduction': {
			return <IntroductionHandler />;
		}
		case 'consent': {
			return <ConsentHandler />;
		}
		case 'calibration': {
			return <CalibrationHandler />;
		}
		case 'inform': {
			return <InformHandler />;
		}
		case 'trialRunner': {
			return (
				<TrialRunnerContextProvider>
					<TrialRunnerHandler />
				</TrialRunnerContextProvider>
			);
		}
		case 'conclusion': {
			return <ConclusionHandler />;
		}
		default: {
			throw new Error(`Unhandled stage type`);
		}
	}
};
