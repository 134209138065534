/**
 * Default values if User does not specify property
 */
const defaults = {
	firstStageIndex: 0,
	expConfigFileName: 'expConfig.json',
	theme: {
		colorPrimary: 'teal',
		inputColor: 'teal'
	},
	global: {
		nextButtonDelay: 1000
	},
	introduction: {
		title: 'Welcome to Our Experiment'
	},
	trialRunner: {
		stimuliFilename: 'test-stimuli.csv',
		experimentsDirname: 'experiments',
		exampleExperimentsDirname: 'experiments/examples',
		stimuliDirname: 'stimlist',
		pauseAfterLastTrial: 2000,
		pauseBeforeFirstTrial: 2000,
		waitAfterTrial: [1000],
		presentation: {
			audio: {
				dirname: 'audio',
				addExtension: '',
				clearScreen: false,
				waitBefore: 0,
				waitAfter: 0,
				feedbackTime: 20000
			},
			image: {
				dirname: 'images',
				addExtension: '',
				clearScreen: false,
				waitBefore: 0,
				waitAfter: 0,
				feedbackTime: 20000
			},
			text: {
				dirname: 'text',
				addExtension: '',
				clearScreen: false,
				waitBefore: 0,
				waitAfter: 0,
				feedbackTime: 20000
			},
			concurrent: {
				waitBefore: 0,
				waitAfter: 0,
				clearScreen: false,
				feedbackTime: 20000,
				types: {
					audio: {
						dirname: 'audio',
						addExtension: '',
						waitBefore: 0,
						waitAfter: 0,
						feedbackTime: 20000
					},
					image: {
						dirname: 'images',
						addExtension: '',
						waitBefore: 0,
						waitAfter: 0,
						feedbackTime: 20000
					},
					text: {
						dirname: 'text',
						addExtension: '',
						waitBefore: 0,
						waitAfter: 0,
						feedbackTime: 20000
					}
				}
			}
		},
		response: {
			timeToRespond: 3000
		}
	},
	consent: {
		title: 'Consent to Participate',
		consentText: `

				We are asking for your consent to be a participant in this research study, which examines how
				attention can affect word perception. The knowledge we gain from this study will contribute to our
				understanding of how individuals process spoken language.


				In this experiment, you will be presented with words over headphones and asked to attend to only one
				side. In each trial, you will be asked to decide whether you heard a P, B, T, or D in the word you
				attended to by pressing a button on the keyboard. The experiment will last approximately 15 minutes,
				and then a demographic survey will last approximately 5 minutes. There are few risks to
				participating in this study. They are comparable to normal use of a computer.


				By consenting to participate, you agree that the purpose of the study, what will happen during the
				study, and the length of time it will take you to complete the study have been explained to you. In
				addition, you acknowledge that the possible risks and benefits of the study have been described, as
				have alternative procedures, if such procedures are applicable and available.

				Data collected in this study might be made available to other researchers, but any data will be
				distributed anonymously, with no personal information linking you to the data.

				If for any reason you decide that you no longer want to participate, you are free to exit the
				survey. If you choose to exit the survey before completion, you will not receive the compensation
				listed on MTurk.

				Should you have concerns or additional questions after completing the study, contact Mark Pitt or
				Marjorie Freggens at freggens.1@osu.edu, Department of Psychology, 281 Psychology Building,
				292-4882.

				For questions about your rights as a participant in this study or to discuss other study-related
				concerns or complaints with someone who is not part of the research team, you may contact Ms. Sandra
				Meadows in the Ohio State University Office of Responsible Research Practices at 1-800-678-6251.

				If you are injured as a result of participating in this study or for questions about a study-related
				injury, contact Mark Pitt or Marjorie Freggens, Department of Psychology, 281 Psychology Building,
				292-488-xxxx.

		`
	},
	conclusion: {
		title: 'Conclusion'
	}
};

export default defaults;
