import { FC } from 'react';
import styled from 'styled-components';
import rhp from 'react-html-parser';
import { useExperimentContext } from 'context';

const StyleResetter = styled.div`
	ul {
		list-style: circle outside;
		margin-left: 20px;
		padding-left: 0;

		li::before {
			content: '- ';
			font-weight: bold;
			display: inline-block;
			width: 1em;
			margin-left: -1em;
		}
	}
`;

interface Props {
	contentID?: string;
	defaultTo?: string;
	breakNumber?: string;
}

/**
 *
 */
export const Content: FC<Props> = ({ contentID, defaultTo, breakNumber }) => {
	const { content: con } = useExperimentContext();
	const content = (() => {
		const contentElement = con?.querySelector(`#${contentID}`);

		if (contentID === 'breaks') {
			const specificBreak = contentElement?.querySelector(`#b${breakNumber}`);

			if (specificBreak) return specificBreak;
			return contentElement?.querySelector(`#default`);
		}
		return contentElement;
	})();

	return (
		<StyleResetter>
			{content ? rhp(content.outerHTML, { decodeEntities: true }) : content === null ? defaultTo : ''}
		</StyleResetter>
	);
};
