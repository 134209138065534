/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, FC } from 'react';
import { InformStage, TrialRunnerStage } from 'types';
import { ResponsePhaseContextProvider, useExperimentContext, useTrialRunnerContext } from 'context';
import { smartSetTimeout } from 'utility';
import { Scoreboard } from 'components';
import { PresentationPhase, ResponsePhase } from './trialRunnerPhases';
import { InformHandler } from './InformHandler';
/**
 * Handles a Trial Runner stage
 */
const startTime =  Date.now();
export const TrialRunnerHandler: FC = () => {
	const { updateExpeimentDuration,addBlockResults, goToNextStage, content, currentStage, experimentStartTime } = useExperimentContext();
	const stage = currentStage as TrialRunnerStage;
	const { currentPhase, blockResults, noMoreBlocks, endPhase, isFeedback, effectiveBlockIndex } =
		useTrialRunnerContext();

	const [waitingBeforeFirstTrial, setWaitingBeforeFirstTrial] = useState(Boolean(stage.pauseBeforeFirstTrial));
	const [phaseElement, setPhaseElement] = useState<FC>();
	var localExperimentDuration = experimentStartTime;
	// Wait before the first trial
	useEffect(() => {
		if (waitingBeforeFirstTrial) {
			smartSetTimeout(() => setWaitingBeforeFirstTrial(false), stage.pauseBeforeFirstTrial);
		}
	}, []);

	// End the trial, potentially
	useEffect(() => {
		if (noMoreBlocks) {
			addBlockResults(blockResults);
			localExperimentDuration = (Date.now() - startTime) + experimentStartTime;
			updateExpeimentDuration(localExperimentDuration/1000);
			smartSetTimeout(() => goToNextStage(), stage.pauseAfterLastTrial);
		}
	}, [noMoreBlocks]);

	// Change phase element
	useEffect(() => {
		switch (currentPhase) {
			case 'presenting':
				setPhaseElement(() => <PresentationPhase currentStage={stage} />);
				break;
			case 'responding':
				setPhaseElement(() => (
					<ResponsePhaseContextProvider>
						<ResponsePhase />
					</ResponsePhaseContextProvider>
				));
				break;
			case 'break':
				if (effectiveBlockIndex && content?.querySelector('#breaks')) {
					setPhaseElement(() => (
						<InformHandler
							//MP: Removed title of break screens
                            //stageOverride={{ title: 'Break', content: 'breaks' } as InformStage}
							stageOverride={{ title: ' ', content: 'breaks' } as InformStage}
							nextHandler={endPhase}
							breakNumber={effectiveBlockIndex.toString()}
							delay={stage.waitAfterTrial?.[effectiveBlockIndex - 1]}
						/>
					));
				} else {
					endPhase();
				}
				break;
		}
	}, [currentPhase]);

	let display;
	if (waitingBeforeFirstTrial) {
		display = <div style={{ textAlign: 'center' }} />;
	} else if (noMoreBlocks) {
		display = (
			<div style={{ textAlign: 'center' }}>
				<h3>Please wait</h3>
				<br />
			</div>
		);
	} else {
		display = (
			<>
				{isFeedback && <Scoreboard />}
				{phaseElement}
			</>
		);
	}

	return display;
};
