import { useExperimentContext } from 'context';
import { FC, useState } from 'react';
import { Button, Header } from 'ui';

/**
 * Runs a Headphone Test
 */
export const VolumeTest: FC = () => {
	const { goToNextStage, srcToAudioMap } = useExperimentContext();
	const [showNextButton, setShowNextButton] = useState(false);

	const playSpark = async () => {
		await (srcToAudioMap.get('$spark') as HTMLAudioElement).play();

		setTimeout(() => setShowNextButton(true), 1000);
	};

	return (
		<>
			<Header>Volume Test</Header>
			<div style={{ margin: '40px 0' }}>
				<h5>
                    Please put on earbuds or headphones <br /> <br />
					Adjust the volume on your computer until you can clearly and comfortably hear the word through your
					headphones, pressing the play button as needed.
				</h5>
				<h5 data-testid={'spark-button-container'}>
					Press{' '}
					<Button color='green' onClick={playSpark}>
						Play
					</Button>{' '}
					to hear the word <strong>"spark"</strong>
				</h5>
			</div>
			<Button showIf={showNextButton} onClick={() => goToNextStage()}>
				Next
			</Button>
		</>
	);
};
