import { useExperimentContext } from 'context';
import { FC } from 'react';
import { CalibrationStage } from 'types';
import { StereoTest, MicTest, VolumeTest } from './calibrations';

/**
 * Handles a System Calibration
 */
export const CalibrationHandler: FC = () => {
	const { currentStage } = useExperimentContext();
	const stage = currentStage as CalibrationStage;
	switch (stage.test) {
		case 'volume': {
			return <VolumeTest />;
		}
		case 'stereo': {
			return <StereoTest />;
		}
		case 'mic': {
			return <MicTest />;
		}
		default: {
			throw new Error(`No such test exists "${stage.test}"`);
		}
	}
};
