import { FC } from 'react';
import { useResponsePhaseContext, useTrialRunnerContext } from 'context';
import { KeypressResponse, MicResponse, TypingResponse } from './responses';

/**
 * The Response Phase of a Trial
 */
export const ResponsePhase: FC = () => {
	const { currentRow } = useTrialRunnerContext();
	const { responded, didntRespond, goToNextTrial, currentResponse, currentResponseIndex, feedback } =
		useResponsePhaseContext();

	if (!currentRow) throw new Error('No current row');

	// If we are not waiting for user input, keep screen blank
	if (responded) {
		return (
			<div style={{ textAlign: 'center' }}>
				{didntRespond ? (
					<>
						<h3>Respond quicker!</h3>
						<br />
					</>
				) : (
					<br />
				)}
				{feedback !== '' ? (
					<>
						<h3>{feedback}!</h3>
						<br />
					</>
				) : (
					<br />
				)}
			</div>
		);
	}

	if (!currentResponse) {
		goToNextTrial();
		return <></>;
	}

	switch (currentResponse.type) {
		case 'typing': {
			return <TypingResponse key={currentResponseIndex} currentResponse={currentResponse} />;
		}
		case 'keypress': {
			return <KeypressResponse key={currentResponseIndex} currentResponse={currentResponse} />;
		}
		case 'mic': {
			return <MicResponse key={currentResponseIndex} currentResponse={currentResponse} />;
		}
		default: {
			throw new Error('Unhandled response type');
		}
	}
};
