import { Navbar } from 'components';
import { BrowserView, deviceType, isDesktop, isMobile, isMobileOnly, isTablet, MobileView } from "react-device-detect";
import { useParams, useSearchParams } from 'react-router-dom';
import { detect } from 'detect-browser';
import { ExperimentRunner } from './experimentRunner';
import { ExperimentContextProvider } from 'context';

/**
 * Page for running an Experiment
 *
 * Takes in the name of the experiment in the url
 */
export function Experiment() {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const browser = detect();

	const stimList = searchParams.get('stimList');
	const pcode = searchParams.get('pcode');
	if ((browser!.name === 'chrome' || browser!.name === 'firefox') && (isTablet || isDesktop)) {
		return (
			<>
				<Navbar />
				<div style={{ margin: '50px 20%' }}>
					<ExperimentContextProvider experimentName={params!.name} stimList={stimList} pcode={pcode}>
						<ExperimentRunner />
					</ExperimentContextProvider>
				</div>
			</>
		);
	} else if(isMobileOnly){
		window.alert('This experiment is unsupported on mobile devices. Please switch to a desktop, laptop, or tablet to continue.');
	} else {
		window.alert('UNSUPPORTED BROWSER. Please switch to Chrome or Firefox');
		alert('UNSUPPORTED BROWSER. Please switch to Chrome or Firefox');
		console.log('"UNSUPPORTED BROWSER. Please switch to Chrome or Firefox"');
	}
	return <></>;
}
