/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, Fragment, useCallback, useEffect } from 'react';
import { useExperimentContext, useTrialRunnerContext } from 'context';
import { TrialRunnerStage, AudioPresentation } from 'types';
import { getImageFilePath } from 'utility';
import { defaults, wait } from 'utility';
import { TextPresentation, PicturePresentation } from 'components';

interface Props {
	currentStage: TrialRunnerStage;
}

export const PresentationPhase: FC<Props> = ({ currentStage }) => {
	const { srcToAudioMap, headerToIndexMap, experimentName } = useExperimentContext();
	const { presentationElements, endPhase, addPresentationElement, resetPresentationElements, currentRow } =
		useTrialRunnerContext();
	const [currentPresentationIndex, setCurrentPresentationIndex] = useState(0);

	const presentStimuli = useCallback(async () => {
		const createAudioStimuli = (presentation: AudioPresentation) => {
			const fileColumnHeader = presentation.fileNameColumn as string;
			const fileColumnIndex = headerToIndexMap?.get(fileColumnHeader) as number;

			const fileName = currentRow[fileColumnIndex] as string;

		//	console.log(fileColumnHeader, fileColumnIndex, fileName);

			return srcToAudioMap.get(fileName);
		};

		const goToNextPresentation = () => {
			if (currentPresentationIndex === currentStage.presentations.length - 1) {
				endPhase();
			} else {
				setCurrentPresentationIndex((currentPresentationIndex) => currentPresentationIndex + 1);
			}
		};

//		console.log(currentStage.presentations);
		const { presentations } = currentStage;
		const presentation = presentations[currentPresentationIndex];
		if (!presentation) throw new Error('No presentation');
		if (!currentRow) throw new Error('Current row does not exist');
		if (presentation.clearScreen) {
			resetPresentationElements();
			srcToAudioMap.forEach((audio) => audio.pause());
		}
		switch (presentation.type) {
			case 'audio': {
				const defaultConfig = defaults.trialRunner.presentation.audio;

				const audio = createAudioStimuli(presentation);
				if (!audio) throw new Error('bad');

				await wait(presentation.waitBefore ?? defaultConfig.waitBefore);

				await audio.play();

				if (presentation.waitTillFinished === true) {
					await wait(audio.duration * 1000);
				}

				await wait(presentation.waitAfter ?? defaultConfig.waitAfter);

				break;
			}
			case 'text': {
				if (presentation.textColumn === undefined) throw new Error('No source column specified');

				const sourceColumnIndex = headerToIndexMap?.get(presentation.textColumn);
				const defaultConfig = defaults.trialRunner.presentation.text;

				await wait(presentation.waitBefore ?? defaultConfig.waitBefore);

				if (!sourceColumnIndex) throw new Error('Header column does not exist');

				const text = currentRow[sourceColumnIndex];

				if (!text) throw new Error('No source exists');

				// if no position found, should be at left
				if (presentation.x === undefined || presentation.y === undefined) {
					presentation.x = -1;
					presentation.y = -1;
				}

				// if no font size, the default is 50
				if (presentation.fontSize === undefined) {
					presentation.fontSize = 50;
				}

				addPresentationElement({
					element: (
						<TextPresentation
							text={text}
							x={presentation.x}
							y={presentation.y}
							fontSize={presentation.fontSize}
						/>
					),
					showDuringResponse: presentation.showDuringResponse ?? true
				});

				// waitAfter control for "text"
				await wait(presentation.waitAfter ?? defaultConfig.waitAfter);
				break;
			}
			case 'image': {
				if (presentation.fileNameColumn === undefined) throw new Error('No source column specified');
				const defaultConfig = defaults.trialRunner.presentation.image;
				const fileName = currentRow[headerToIndexMap?.get(presentation.fileNameColumn) as number];

				// if (presentation.correctAnswer != null) {
				// 	const answer = currentRow[headerToIndexMap.get(presentation.correctAnswer) as number]
				// 	console.log("answer: " + answer);
				// }
				//
				// console.log("fileName: " + fileName);

				// console.log("the column name of answer is : " + presentation.correctAnswer);
				// const xpos = currentRow[headerToIndexMap.get(presentation.x) as number]

				// waitBefore control for "image"
				await wait(presentation.waitBefore ?? defaultConfig.waitBefore);
				if (!fileName) throw new Error('No source exists');

				const fullFileName = `${fileName}${presentation.addExtension ?? defaultConfig.addExtension}`;
				const src = getImageFilePath(experimentName!, fullFileName);

				// if no position found, add normally
				if (presentation.x === undefined || presentation.y === undefined) {
					presentation.x = -1;
					presentation.y = -1;
				}

				if (presentation.h === undefined || presentation.w === undefined) {
					presentation.w = -1;
					presentation.h = -1;
				}

				addPresentationElement({
					element: (
						<PicturePresentation
							src={src}
							alt='yeah'
							x={presentation.x}
							y={presentation.y}
							h={presentation.h}
							w={presentation.w}
						/>
					),
					showDuringResponse: presentation.showDuringResponse ?? true
				});

				// waitAfter control for "image"
				await wait(presentation.waitAfter ?? defaultConfig.waitAfter);

				break;
			}

			case 'concurrent': {
				const defaultConfig = defaults.trialRunner.presentation.concurrent;
				const { presentations } = presentation;

				/*
					{
						presentations: [
							top-level presentations will be run sequentially
							{
								type: "concurrent"
								waitBefore: "applied before starting any presentations",
								inner presentations will be spun up to run in parallel
								"presentations": [
									{
										type: "audio"
										waitBefore: "will generate a setTimeout to present stimuli after waiting period but without blocking other stimuli",
										will add an await to the audio as either part of the setTimeout function or directly before playing?
										waitTillFinished: true
									},
									{

									}
								],
								"waitAfter all stimuli have begun presenting, but not necessarily finished presenting"
							},
							{
								type: "text",
								...
							}
						]
					}
				*/

				if (!presentations) throw new Error('No presentations in concurrent presentation');
				await wait(presentation.waitBefore ?? defaultConfig.waitBefore);

				const presentationPromises = presentations.map(async (pres) => {
					switch (pres.type) {
						case 'image': {
							if (pres.fileNameColumn === undefined) throw new Error('No source column specified');
							const fileName = currentRow[headerToIndexMap?.get(pres.fileNameColumn) as number];
							const fullFileName = `${fileName}${
								pres.addExtension ?? defaultConfig.types.image.addExtension
							}`;
							const src = getImageFilePath(experimentName!, fullFileName);

							// if no position found, add normally
							if (pres.x === undefined || pres.y === undefined) {
								pres.x = -1;
								pres.y = -1;
							}

							if (pres.h === undefined || pres.w === undefined) {
								pres.w = -1;
								pres.h = -1;
							}

							const waitBefore = pres.waitBefore ?? defaultConfig.waitBefore;
							const waitAfter = pres.waitAfter ?? defaultConfig.waitAfter;

							if (waitBefore > 0) {
								await wait(waitBefore);
							}

							addPresentationElement({
								element: (
									<PicturePresentation
										src={src}
										alt='yeah'
										x={pres.x}
										y={pres.y}
										h={pres.h}
										w={pres.w}
									/>
								),
								showDuringResponse: pres.showDuringResponse ?? true
							});

							if (waitAfter > 0) {
								await wait(waitAfter);
							}

							break;
						}
						case 'audio': {
							const defaultConfig = defaults.trialRunner.presentation.concurrent.types.audio;

							const audio = createAudioStimuli(pres);
							if (!audio) throw new Error('bad');

							const waitBefore = pres.waitBefore ?? defaultConfig.waitBefore;
							const waitAfter = pres.waitAfter ?? defaultConfig.waitAfter;

							if (waitBefore > 0) {
								await wait(waitBefore);
							}

							await audio.play();

							if (pres.waitTillFinished === true) {
								await wait(audio.duration * 1000);
							}

							if (waitAfter) {
								await wait(waitAfter);
							}

							break;
						}
						case 'text': {
							if (pres.textColumn === undefined) throw new Error('No source column specified');

							const sourceColumnIndex = headerToIndexMap?.get(pres.textColumn);
							const defaultConfig = defaults.trialRunner.presentation.concurrent.types.text;

							if (!sourceColumnIndex) throw new Error('Header column does not exist');

							const text = currentRow[sourceColumnIndex];

							if (!text) throw new Error('No source exists');

							// if no position found, should be at left
							if (pres.x === undefined || pres.y === undefined) {
								pres.x = -1;
								pres.y = -1;
							}

							// if no font size, the default is 50
							if (pres.fontSize === undefined) {
								pres.fontSize = 50;
							}

							const waitBefore = pres.waitBefore ?? defaultConfig.waitBefore;
							const waitAfter = pres.waitAfter ?? defaultConfig.waitAfter;

							if (waitBefore > 0) {
								await wait(waitBefore);
							}

							addPresentationElement({
								element: (
									<TextPresentation text={text} x={pres.x} y={pres.y} fontSize={pres.fontSize} />
								),
								showDuringResponse: pres.showDuringResponse ?? true
							});

							if (waitAfter > 0) {
								await wait(waitAfter);
							}
							break;
						}
						default: {
							throw new Error('Unhandled concurrent presentation type');
						}
					}
				});

				await Promise.all(presentationPromises);

				await wait(presentation.waitAfter ?? defaultConfig.waitAfter);

				break;
			}
			default: {
				throw new Error('Unhandled presentation type');
			}
		}
		goToNextPresentation();
	}, [
		currentStage,
		currentPresentationIndex,
		currentRow,
		headerToIndexMap,
		srcToAudioMap,
		endPhase,
		addPresentationElement,
		experimentName
	]);

	useEffect(() => {
		presentStimuli();
	}, [currentPresentationIndex]);

	if (presentationElements.length === 0) {
		return <></>;
	}

	return (
		<>
			{presentationElements.map((pe, i) => (
				<Fragment key={i}>{pe.element}</Fragment>
			))}
		</>
	);
};
export const getCorrectAnswer = [];
