import { FC, useCallback, useEffect } from 'react';
import { useResponsePhaseContext, useTrialRunnerContext } from 'context';
import { MicResponse as MR } from 'types';
import { defaults } from 'utility';
import recording from 'assets/recording.png';

interface Props {
	currentResponse: MR;
	trialNumber?: number;
	correctAnswer?: string;
}

export const MicResponse: FC<Props> = ({ currentResponse }) => {
	const { addUserMicResponse } = useResponsePhaseContext();
	const { presentationElements } = useTrialRunnerContext();
	const { x } = currentResponse;
	const { y } = currentResponse;
	const height = currentResponse.height ? currentResponse.height : 1;

	const record = useCallback(async () => {
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		const recorder = new MediaRecorder(stream);
		const audioChunks: Blob[] = [];

		recorder.ondataavailable = (event) => {
			audioChunks.push(event.data);
		};

		recorder.onstop = () => {
			const blob = new Blob(audioChunks, { type: 'audio/webm;codecs=opus' });
			const url = URL.createObjectURL(blob);
			addUserMicResponse(blob, url);
		};

		recorder.start();

		const timeToRespond = currentResponse.timeToRespond ?? defaults.trialRunner.response.timeToRespond;

		setTimeout(() => recorder.stop(), timeToRespond);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		record();
	}, [record]);

	return (
		<>
		{currentResponse.message && (
				<h3
					style={{
						textAlign: 'center',
						top: y ? `${(y * window.innerHeight) / 100 - (50 + 25 * height)}px` : 'auto',
						left: x ? `${(x * window.innerWidth) / 100 - 100}px` : 'auto',
						width: 'auto',
						position: x ? 'absolute' : 'relative'
					}}
				>
					{currentResponse.message}
				</h3>
			)}
			{presentationElements.filter((pe) => pe.showDuringResponse).map((pe) => pe.element)}
			<h3>Collecting mic response...</h3>
			<span
				style={{
					textAlign: 'center',
					marginTop: '30px',
					top: y ? `${(y * window.innerHeight) / 100}px` : 'auto',
					left: x ? `${(x * window.innerWidth) / 100}px` : 'auto',
					width: 'auto',
					position: 'absolute'
				}}
			>
				<img src={recording} alt='mic' style={{ display: 'block' }} />
			</span>
		</>
	);
};
