import {
	ExperimentSettings as ES,
	IntroductionStage,
	ConsentStage,
	InformStage,
	CalibrationStage,
	ConclusionStage,
	TrialRunnerStage,
	DeepRequired
} from 'types';
import { defaults } from 'utility';

/**
 *
 * @param value
 * @param message
 */
const assert = (value: any, message: string) => {
	if (!value) throw new Error(message);
};

/**
 * Validates the expConfig and provides default values
 */
export interface ExperimentConfiguration extends DeepRequired<ES> {}
export class ExperimentConfiguration {
	experimentName: string;

	fileNameColumn: string;

	private static validateIntroductionStage(s: IntroductionStage) {
		s.title = s.title ?? 'Welcome to Our Experiment!';
		s.nextButtonDelay = s.nextButtonDelay ?? 1000;
		s.pcodeEnterMode = s.pcodeEnterMode ?? 'auto';
	}

	private static validateConsentStage(s: ConsentStage) {
		s.title = s.title ?? 'Consent to Participate in Our Experiment';
	}

	private static validateCalibrationStage(s: CalibrationStage) {
		assert(s.test, 'Test type required');
	}

	private static validateInformStage(s: InformStage) {
		assert(s.title, 'Title required');
		s.nextButtonDelay = s.nextButtonDelay ?? 1000;
	}

	private static validateTrialRunnerStage(s: TrialRunnerStage, stimListVersion: string) {
		assert(s.presentations, 'At least one presentation must be specified');
		assert(s.responses, 'At least one response must be specified');
		s.order = s.order ?? 'sequential';
		s.pauseAfterLastTrial = s.pauseAfterLastTrial ?? 2000;
		s.pauseBeforeFirstTrial = s.pauseBeforeFirstTrial ?? 2000;
		s.waitAfterTrial = s.waitAfterTrial ?? [2000];

		s.presentations.forEach((p) => {
			p.waitAfter = p.waitAfter ?? 0;
			p.waitBefore = p.waitBefore ?? 0;

			switch (p.type) {
				case 'audio': {
					assert(p.fileNameColumn, 'File name column not specified');

					break;
				}
				case 'image': {
					assert(p.fileNameColumn, 'File name column not specified');
					p.showDuringResponse = p.showDuringResponse ?? true;

					break;
				}
				case 'text': {
					assert(p.textColumn, 'Text column must be specified');
					p.showDuringResponse = p.showDuringResponse ?? true;

					break;
				}
				case 'concurrent': {
					break;
				}
				default: {
					throw new Error('Unhandled presentation type');
				}
			}
		});

		s.stimuliSource = `${stimListVersion || 'stimuli'}.csv`;

		s.responses.forEach((r) => {
			const defaultTimeToRespond = defaults.trialRunner.response.timeToRespond;
			r.timeToRespond = r.timeToRespond ?? defaultTimeToRespond;

			switch (r.type) {
				case 'typing': {
					break;
				}
				case 'keypress': {
					break;
				}
				case 'mic': {
					break;
				}
				default: {
					throw new Error('Unhandled response type');
				}
			}
		});
	}

	private static validateConclusionStage(conclusionStage: ConclusionStage) {
		conclusionStage.title = conclusionStage.title ?? 'Thanks for Participating!';
	}

	private validateStages(stages: ES['stages'], local: ES['local'], stimListVersion: string) {
		assert(stages, 'Stages property is required');

		const currentLocal = local?.find((loc) => loc.stimList === stimListVersion);

		if (currentLocal) {
			currentLocal.overrides.forEach((override) => {
				const stage = stages.find((stage) => stage.type === override.type);

				if (stage) {
					Object.assign(stage, override);
				} else {
					throw new Error('No such stage name found in the override');
				}
			});
		}

		stages.forEach((stage) => {
			switch (stage.type) {
				case 'introduction': {
					ExperimentConfiguration.validateIntroductionStage(stage);
					break;
				}
				case 'consent': {
					ExperimentConfiguration.validateConsentStage(stage);
					break;
				}
				case 'calibration': {
					ExperimentConfiguration.validateCalibrationStage(stage);
					break;
				}
				case 'inform': {
					ExperimentConfiguration.validateInformStage(stage);
					break;
				}
				case 'trialRunner': {
					ExperimentConfiguration.validateTrialRunnerStage(stage, stimListVersion);
					break;
				}
				case 'conclusion': {
					ExperimentConfiguration.validateConclusionStage(stage);
					break;
				}
				default: {
					throw new Error('Unhandled Trial Runner Stage');
				}
			}
		});

		return stages as DeepRequired<ES['stages']>;
	}

	private getFileNameColumn(stages: ES['stages'], local: ES['local'], stimListVersion: string): string {
		const currentLocal = local?.find((loc) => loc.stimList === stimListVersion);
		if (currentLocal) {
			currentLocal.overrides.forEach((override) => {
				const stage = stages.find((stage) => stage.type === override.type);
				if (stage) {
					Object.assign(stage, override);
				}
			});
		}
		let fileNameColumn = '';
		stages.forEach((stage) => {
			switch (stage.type) {
				case 'trialRunner': {
					stage.presentations.forEach((p) => {
						switch (p.type) {
							case 'audio': {
								return (fileNameColumn = p.fileNameColumn);
							}
							case 'image': {
								return (fileNameColumn = p.fileNameColumn);
							}

							default: {
							}
						}
					});
					break;
				}
				default: {
				}
			}
		});

		return fileNameColumn;
	}

	constructor(settings: ES, experimentName: string, stimListVersion: string) {
		if (typeof settings === 'string') throw new Error('Invalid expConfig syntax');

		this.experimentName = experimentName;

		Object.assign(this, settings);

		this.firstStageIndex = settings.firstStageIndex ?? defaults.firstStageIndex;
		this.collectionName = settings.collectionName ?? experimentName;
		this.stages = this.validateStages(settings.stages, settings.local, stimListVersion);
		this.fileNameColumn = this.getFileNameColumn(settings.stages, settings.local, stimListVersion);
	}
}
