import { useState, FC } from 'react';
import { useExperimentContext } from 'context';
import { IntroductionStage } from 'types';
import { NextButton, Content } from 'components';
import { Header } from 'ui';
import { generateParticipantCode } from 'utility';

/**
 * Handles an Introduction Stage
 * Note: To add a new field, first find it in the Stage.ts, then consider adding a default value to Configurations.ts
 */
export const IntroductionHandler: FC = () => {
	const { experimentResults, goToNextStage, currentStage, pcodeurl, setpcodeurl } = useExperimentContext();

	const stage = currentStage as IntroductionStage;

	const [pCodeEntered, setPCodeEntered] = useState('');
	let pcodeEnteredCheck = false; // checked if there is a pcode is entered by the user.

	// If no pcode is entered by the user, generates one for him.
	function getPcode(): string {
		if (pcodeurl) {
			return pcodeurl;
		}
		if (!pcodeEnteredCheck) {
			return generateParticipantCode();
		}
		return pCodeEntered;
	}

	// Only after the user clicks the summit button, the pcode entered will then be recognized.
	// Later work can be using this function to check if the pcode entered by the user is valid, i.e., 6 characters long with only digits and chars.
	function handleClick() {
		console.log(pCodeEntered);
		console.log(`You have entered this participant code: ${pCodeEntered}`);
		pcodeEnteredCheck = pCodeEntered !== '';
	}

	// Take in the user input and set it to pCodeEntered.
	const handleInput = (event: React.FormEvent<HTMLInputElement>): void => {
		// console.log(event.currentTarget.value);
		setPCodeEntered(event.currentTarget.value);
	};

	const clickedNextButton = () => {
		setpcodeurl(getPcode());
		console.log(experimentResults);
		goToNextStage();
	};
	return (
		<>
			<Header>{stage.title}</Header>
			<Content contentID={stage.content} />
			{stage.pcodeEnterMode === 'manual' ? (
				<div>
					<br />
					<h5>Participant Code</h5>
					<p>
						If you have a participant code, enter it here! Leave it blank if you don't have a participant
						code, the system will generate one for you.
					</p>
					<input type='text' onChange={handleInput} />
					<button onClick={handleClick}>Submit user pcode</button>
				</div>
			) : (
				<></>
			)}
			<br />
			<NextButton toDo={clickedNextButton} delay={stage.nextButtonDelay} />
		</>
	);
};
