/* eslint-disable react-hooks/exhaustive-deps */
import { ExperimentResults, StimulusData } from 'types';
const AUDIO_API_URL = process.env.REACT_APP_AUDIO_API_URL!;
const RESULTS_API_URL = process.env.REACT_APP_RESULTS_API_URL!;

interface Props {
	exp_data: ExperimentResults;
	stim_data: StimulusData;
	stim_header: string[];
	stim_list: string;
	experiment_start_time: string;
	experiment_end_time: string;
	experiment_name: string;
	experiment_file_name_column: string;
}

class SaveData {
	exp_data: ExperimentResults;
	stim_data: StimulusData;
	stim_header: string[];
	stim_list: string;
	experiment_start_time: string;
	experiment_end_time: string;
	experiment_name: string;
	experiment_file_name_column: string;

	constructor(props: Props) {
		this.exp_data = props.exp_data;
		this.stim_data = props.stim_data;
		this.stim_header = props.stim_header;
		this.stim_list = props.stim_list;
		this.experiment_start_time = props.experiment_start_time;
		this.experiment_end_time = props.experiment_end_time;
		this.experiment_name = props.experiment_name;
		this.experiment_file_name_column = props.experiment_file_name_column;
	}

	// Called to format multi-dimensional array into multiple JSON objects
	arrayToJSONObject(arr: any[], header_arr: string[]) {
		console.log(arr);
		// Getting rid of blank row
		arr.shift();
		const keys = arr[0];

		// vacate keys from main array
		const newArr = arr.slice(1, arr.length);

		const formatted = [];
		const data = newArr;
		const cols = keys;
		const l = cols.length;
		for (let i = 0; i < data.length; i++) {
			const d = data[i];
			const o: any = {};
			for (let j = 0; j < l; j++) o[cols[j]] = d[j];
			formatted.push(o);
		}
		console.log(formatted);
		return formatted;
	}

	// Sends experiment results, stimulus information, and tester information to server
	async saveToDb(output: object[], experiment_name: string, information: object[]) {
		const result = {
			resultObj: output,
			experimentName: experiment_name,
			informationArr: information
		};

		await fetch(RESULTS_API_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(result)
		}).catch((error) => {
			window.alert(error);
		});
	}

	async sendAudioFile(file: Blob, pcode: any, exp_name: any, fileNameStr: string) {
		console.log(fileNameStr);
		const data = new FormData();
		data.append('pCode', pcode);
		data.append('stimlist',this.stim_list);
		data.append('audioFileName', fileNameStr);
		data.append('expName', exp_name);
		data.append('file', file);

		await fetch(AUDIO_API_URL, {
			method: 'post',
			body: data
		}).catch((error) => {
			window.alert(error);
		});
	}

	async output_to_db() {
		// To store all audio file
		let audio_result: any[] = [];
		const output: string[][] = [[]];
		const headerArr: any[] = this.stim_header;
		const headerArrConcat = headerArr.concat(['response', 'responseTime', 'pcode','stimlist']);
		output.push(headerArrConcat);
		const { pcode, blockOrder } = this.exp_data;
		// loop through each block in the results, saving block and trial number
		blockOrder?.forEach((block_number, block_index) => {
			const block = this.exp_data.blockResults[block_number]!;
			// loop through each response
			block.responses.forEach((response, response_index) => {
				const trial_number = `${response.trialNumber}`;
				console.log(trial_number);
				let stimuli: string[] = [];
				// look for the matching line in the stimuli list

				const fileIndex = this.stim_header.indexOf(this.experiment_file_name_column);
				this.stim_data.forEach((stim_block) => {
					stim_block.forEach((stim) => {
						// if we have a match, save it
						if (stim[0] === `${block_number}` && stim[1] === trial_number) {
							stimuli = stim;
							// When the response type is the audio
							// @ts-ignore
							if (response.answer instanceof Blob) {
								const audio_download_object = document.createElement('a');
								audio_download_object.download = `${pcode}_${block_number}_${trial_number}.ogg`;
								// saveaudioFile(response.answer,block_number,pcode,trial_number)
								const url = URL.createObjectURL(response.answer);
								audio_download_object.href = url;
								audio_download_object.addEventListener('click', (e) => {
									setTimeout(() => URL.revokeObjectURL(audio_download_object.href), 30 * 1000);
								});
								const blobResponse = response.answer as Blob;
								audio_result.push(audio_download_object);
								const fileName = stim[fileIndex];
								console.log(fileName);
								const fileNameStr = fileName!.substring(0, fileName!.lastIndexOf('.'));
								audio_result.forEach((element) => {
									// this is how it saves audio to disc currently
									const str = element.download as string;
									const splitted = str.split('_');
									// TODO: This could use better error handling
									this.sendAudioFile(blobResponse, splitted[0], this.experiment_name, fileNameStr);
								});
								audio_result = [];
							}
						}
					});
				});
				// add the results and push
				const row: any[] = stimuli;
				const finalObject: string[] = row.concat([
					response.answer,
					`${Math.round(response.responseTime)}`,
					pcode,
					this.stim_list
				]);
				output.push(finalObject);
				//output.push(this.stim_list);
			});
		});

		const information: any[][] = [[]];
		const informationHeader: string[] = ['pcode', 'experimentStartTime', 'experimentEndTime', 'experimentDuration','stimulusList'];
		information.push(['pcode', 'experimentStartTime', 'experimentEndTime', 'experimentDuration','stimulusList']);
		information.push([
			pcode,
			this.experiment_start_time,
			this.experiment_end_time,
			`${(this.exp_data.experimentDuration / 60000).toFixed(1)} minutes`,
			this.stim_list
		]);
		const JSONResultsarr = this.arrayToJSONObject(output, headerArrConcat);
		const JSONInformationarr = this.arrayToJSONObject(information, informationHeader);
		await this.saveToDb(JSONResultsarr, this.experiment_name, JSONInformationarr);

		return output;
	}
}

export default SaveData;
