import { FC } from 'react';

interface Props {
	src: string;
	x: number;
	y: number;
	h: number;
	w: number;
	alt: string | undefined;
}

export const PicturePresentation: FC<Props> = ({ src, x, y, h, w, alt = 'yeah' }) => (
	<span
		style={{
			textAlign: 'center',
			marginTop: '30px',
			top: y >= 0 ? `${(y * window.innerHeight) / 100}px` : 'auto',
			left: x >= 0 ? `${(x * window.innerWidth) / 100}px` : 'auto',
			width: 'auto',
			position: x >= 0 || y >= 0 ? 'absolute' : 'static'
		}}
	>
		<img
			src={src}
			width={w >= 0 ? `${w}px` : '150px'}
			height={h >= 0 ? `${h}px` : 'auto'}
			alt={alt}
			style={{ display: 'block' }}
		/>
	</span>
);
