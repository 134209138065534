/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useMemo, createContext, FC } from 'react';
import { PresentationElement, TrialRunnerPhase, ExperimentResults, TrialRunnerStage } from 'types';
import { useExperimentContext } from './ExperimentContext';

interface Props {}
let effectiveTrialIndex = 0;
let currentRow: any;
const useValue = () => {
	const {
		srcToAudioMap,
		headerToIndexMap,
		stimulusData,
		currentStage: cs,
		experimentResults
	} = useExperimentContext();

	const [currentPresentationIndex] = useState(0);
	const [currentTrialIndex, setCurrentTrialIndex] = useState(0);
	const [currentPhase, setCurrentPhase] = useState<TrialRunnerPhase>('presenting');
	const [presentationElements, setPresentationElements] = useState<PresentationElement[]>([]);
	const [blockResults, setBlockResults] = useState<ExperimentResults['blockResults']>([]);
	const [currentBlockIndex, setCurrentBlockIndex] = useState(0);
	const [numCorrect, setNumCorrect] = useState(0);
	const [numIncorrect, setNumIncorrect] = useState(0);
	const [numNotResponded, setNumNotResponded] = useState(0);

	const currentStage = useMemo(() => cs as TrialRunnerStage, []);

	if (stimulusData === null) throw new Error(`if this happens that'd be crazy`);
	const blockOrder = useMemo(() => {
		// console.log("stimulusData " + stimulusData)
		// If the order is input as an array
		if (Array.isArray(currentStage.blockOrder)) {
			return currentStage.blockOrder;
		}

		// If the block order is sequential should not sort it
		if (currentStage.blockOrder === 'sequential' || currentStage.blockOrder == null) {
			return [...new Array(stimulusData?.length).keys()];
		}

		// If the block order is reversed should be reversed
		if (currentStage.blockOrder === 'reversed') {
			return [...new Array(stimulusData?.length).keys()].reverse();
		}

		// Generate a shuffle block order and store it
		const blockShuffleOrder = [...new Array(stimulusData?.length).keys()].sort(() => 0.5 - Math.random());
		return blockShuffleOrder;
	}, []);

	experimentResults.blockOrder = blockOrder;
	const effectiveBlockIndex = blockOrder[currentBlockIndex] as number;
	const trialOrder = useMemo(() => {
		// Catch the last end stimulus, should be put for the fist case here
		if (currentBlockIndex >= blockOrder.length) return [];

		// If the trial order is "sequential" should not sort it
		if (currentStage.trialOrder === 'sequential' || currentStage.trialOrder == null) {
			const trialSequentialOrder = [
				...new Array((stimulusData?.[effectiveBlockIndex] as string[][]).length).keys()
			];

			return trialSequentialOrder;
		}

		// If the trial order is "reversed"  should reverse it
		if (currentStage.trialOrder === 'reversed') {
			const trialSequentialOrder = [
				...new Array((stimulusData?.[effectiveBlockIndex] as string[][]).length).keys()
			].reverse();
			return trialSequentialOrder;
		}

		// Generate a shuffle trial order from a previous effectiveBlockIndex and store it
		const trialShuffleOrder = [
			...new Array((stimulusData?.[effectiveBlockIndex] as string[][]).length).keys()
		].sort(() => 0.5 - Math.random());

		// console.log("shuffleOrder  is " + shuffleOrder);
		return trialShuffleOrder;
	}, [currentBlockIndex]);

	const currentBlock = stimulusData?.[effectiveBlockIndex] ?? [];

	effectiveTrialIndex = trialOrder[currentTrialIndex] as number;
	currentRow = currentBlock[effectiveTrialIndex];

	const areTrialsLeftInBlock = currentBlock[currentTrialIndex + 1] !== undefined;

	const goToNextBlock = () => {
		setCurrentBlockIndex((currentBlockIndex) => currentBlockIndex + 1);
		setCurrentTrialIndex(0);
	};

	const addPresentationElement = (element: PresentationElement) => {
		setPresentationElements((presentationElements) => [...presentationElements, element]);
	};

	const resetPresentationElements = () => {
		setPresentationElements([]);
	};
	const setPause = () => {
		const { presentations } = currentStage;
		const presentation = presentations[currentPresentationIndex];

		if (!presentation) throw new Error('No presentation');
		if (!currentRow) throw new Error('Current row does not exist');
		switch (presentation.type) {
			case 'audio': {
				const fileColumnHeader = presentation.fileNameColumn as string;
				const fileColumnIndex = headerToIndexMap?.get(fileColumnHeader) as number;

				const fileName = currentRow[fileColumnIndex] as string;
				const audio = srcToAudioMap.get(fileName);
				if (!audio) throw new Error('bad');
				audio.pause();
				break;
			}
		}
	};

	const incrementTrialIndex = () => {
		if (areTrialsLeftInBlock) {
			setCurrentTrialIndex((currentTrialIndex) => currentTrialIndex + 1);
		} else {
			goToNextBlock();
		}
	};

	const addTrialResponse = (
		answer: any,
		responseTime: number,
		url?: any,
		trialNumber?: number,
		correctAnswer?: string
	) => {
		if (!currentRow) throw new Error();
		const blockNumber = Number.parseInt(currentRow[0] as string);

		setBlockResults((prevBlockResults) => {
			const newBlockResults = [...prevBlockResults];

			const currentBlockResult =
				newBlockResults[blockNumber] ??
				(() => {
					const newBlockResult: typeof prevBlockResults[0] = {
						blockNumber: blockNumber.toString(),
						responses: []
					};
					newBlockResults[blockNumber] = newBlockResult;
					return { blockNumber: blockNumber.toString(), responses: [] };
				})();

			currentBlockResult.responses.push({ answer, responseTime, url, trialNumber, correctAnswer });
			newBlockResults[blockNumber] = currentBlockResult;
		//	console.log(newBlockResults);
			return newBlockResults;
		});
	};

	const endPhase = () => {
		switch (currentPhase) {
			case 'presenting': {
				setCurrentPhase('responding');
				break;
			}
			case 'responding': {
				const noBreakScreens = (currentStage as TrialRunnerStage).breakScreen === undefined && false;
				const breaks = (currentStage as TrialRunnerStage).breakPositions
				if(breaks){
					if (breaks.indexOf(currentBlockIndex) > -1 && (currentTrialIndex == trialOrder.length - 1) && !noBreakScreens) {
						setCurrentPhase('break');
					} else {
						setCurrentPhase('presenting');
					}
					break;
				} else {
					if (currentTrialIndex !== trialOrder.length - 1 || noBreakScreens) {
						setCurrentPhase('presenting');
					} else {
						setCurrentPhase('break');
					}
					break;
				}
			}
			case 'break': {
				setCurrentPhase('presenting');
				break;
			}
			default: {
				throw new Error();
			}
		}
	};

	const incrementNumCorrect = () => {
		setNumCorrect((prevNumCorrect) => prevNumCorrect + 1);
	};

	const incrementNumIncorrect = () => {
		setNumIncorrect((prevNumIncorrect) => prevNumIncorrect + 1);
	};

	const incrementNumNotResponded = () => {
		setNumNotResponded((prevNumNotResponded) => prevNumNotResponded + 1);
	};

	const noMoreBlocks = currentBlockIndex === stimulusData?.length || !currentRow;
	const isFeedback = Boolean(currentStage.feedback);

	return {
		currentTrialIndex,
		effectiveTrialIndex,

		currentPhase,
		currentStage,

		presentationElements,

		currentBlockIndex,
		effectiveBlockIndex,

		blockResults,

		currentRow,
		areTrialsLeftInBlock,

		goToNextBlock,
		noMoreBlocks,
		isFeedback,

		addPresentationElement,
		resetPresentationElements,
		setPause,
		incrementTrialIndex,
		addTrialResponse,
		endPhase,

		numCorrect,
		numIncorrect,
		numNotResponded,

		incrementNumCorrect,
		incrementNumIncorrect,
		incrementNumNotResponded
	};
};

export const trialRunnerContext = createContext({} as ReturnType<typeof useValue>);
export const useTrialRunnerContext = () => useContext(trialRunnerContext);

export const TrialRunnerContextProvider: FC<Props> = (props) => (
	<trialRunnerContext.Provider value={useValue()}>{props.children}</trialRunnerContext.Provider>
);
