/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { defaults } from 'utility';

const pulse = keyframes`
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
`;

const Bar = styled.div<{ length: number; text?: string }>`
	height: 5px;
	border-radius: 10px;
	animation: ${pulse} ${(props) => props.length}s linear;
	width: 0%;
`;

interface Props {
	doWhenFinished: () => void;
	length: number;
	status: boolean;
}

export const ProgressBar: FC<Props> = ({ doWhenFinished, length, status }) => {
	useEffect(() => {
		const timeout = setTimeout(doWhenFinished, length);

		return () => {
			clearTimeout(timeout);
		};
	}, [doWhenFinished]);
	// When user not define or define it as true, the progressbar will be shown
	if (status || status == null) {
		return <Bar className={`${defaults.theme.colorPrimary}`} length={length / 1000 - 0.2} />;
	}

	return null;
};
