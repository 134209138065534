/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, Fragment } from 'react';
import { useExperimentContext, useResponsePhaseContext, useTrialRunnerContext } from 'context';
import { KeypressResponse as KResponse } from 'types';
import { Button } from 'ui';
import { ProgressBar } from 'components';
import { defaults } from 'utility';

interface Props {
	currentResponse: KResponse;
	trialNumber?: number;
	correctAnswer?: string;
}

/**
 * Handles a Choice Response
 */
export const KeypressResponse: FC<Props> = ({ currentResponse }) => {
	const { headerToIndexMap } = useExperimentContext();
	const { currentRow, presentationElements } = useTrialRunnerContext();
	const { addUserResponse, endResponseForcibly } = useResponsePhaseContext();
	const { x } = currentResponse;
	const { y } = currentResponse;
	const height = currentResponse.height ? currentResponse.height : 1;


	if (!currentRow) throw new Error('shouldnt be here');

	function timeout(delay: number) {
		return new Promise((res) => setTimeout(res, delay));
	}
	useEffect(() => {
		const listener = (e: KeyboardEvent) => {
			currentResponse.choices.forEach(async (choice, responseIndex) => {
				if (choice.buttonPress === e.code && currentResponse.inputType === 'keyBoard') {
					// alert(choice.text)
					// addUserResponse(responseIndex.toString())
					// @ts-ignore
					const response = choice.text.toString();
					const originalcolor = choice.color;
					const originalBrightness = choice.brightness;
					if (originalcolor === 'red') {
						choice.brightness = 1.0;
					} else {
						choice.brightness = 0.5;
					}
					console.log(choice.brightness);
					addUserResponse(response, 'keypress', delay);
					if (delay == null) {
						await timeout(250);
					} else {
						await timeout(delay);
					}

					choice.brightness = originalBrightness;
					// alert("Your selection is " +  choice.text);
				}
			});
		};

		document.addEventListener('keydown', listener);

		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	// Disable mouse clicking when input type is "keyBoard"
	const checkInputType = (answer: string) => {
		if (currentResponse.inputType === 'mouse' || currentResponse.inputType == null) {
			addUserResponse(answer);
		}
	};

	const choiceElements = currentResponse.choices.map((choice, responseIndex) => {
		// @ts-ignore
		// console.log(stimulusData)
		let choiceInString: string = '';
		const buttonText = (() => {
			if (choice.text) {
				choiceInString = choice.text.toString();
				return choiceInString;
			}
			const textColumnHeaderName = choice.textColumn;
			if (!textColumnHeaderName) throw new Error();

			const textColumnIndex = headerToIndexMap?.get(textColumnHeaderName);
			if (!textColumnIndex) throw new Error();

			const textColumnValue = currentRow[textColumnIndex];
			if (!textColumnValue) throw new Error();

			choiceInString = textColumnValue;

			return textColumnValue;
		})();

		if (choice.x === undefined || choice.y === undefined) {
			choice.x = -1;
			choice.y = -1;
		}
		if (choice.x > -1 && choice.y > -1) {
			let responseString = "Press key: " + (choice.buttonPress?.toString())?.substring(4,choice.buttonPress?.toString().length - 1);
			return (
				<div style={{ display: 'flex' }} key={`reponse-${responseIndex}`}>
					<Button
						color={choice.color}
						style={{
							position: 'absolute',
							top: `${choice.y}%`,
							left: `${choice.x}%`,
							width: choice.width,
							height: choice.height,
							opacity: choice.brightness,
							textTransform: 'unset'
						}}
						onClick={() => checkInputType(choiceInString)}
					>
						{buttonText} <br></br> {responseString}
					</Button>
				</div>
			);
		}
		return (
			
			<div style={{ display: 'flex' }} key={`reponse-${responseIndex}`}>
				<Button
					color={choice.color}
					style={{
						float: 'left',
						width: choice.width,
						height: choice.height,
						opacity: choice.brightness
					}}
					onClick={() => checkInputType(choiceInString)}
				>
					{buttonText}
				</Button>
			</div>
		);
	});

	const timeToRespond = currentResponse.timeToRespond ?? defaults.trialRunner.response.timeToRespond;
	const progressBarStatus = currentResponse.progressBarStatus as boolean;
	const { delay } = currentResponse;

	return (
		<>
		{currentResponse.message && (
				<h3
					style={{
						textAlign: 'center',
						top: y ? `${(y * window.innerHeight) / 100 - (50 + 25 * height)}px` : 'auto',
						left: x ? `${(x * window.innerWidth) / 100 - 100}px` : 'auto',
						width: 'auto',
						position: x ? 'absolute' : 'relative'
					}}
				>
					{currentResponse.message}
				</h3>
			)}
			<ProgressBar doWhenFinished={endResponseForcibly} length={timeToRespond} status={progressBarStatus} />
			{presentationElements
				.filter((pe) => pe.showDuringResponse)
				.map((pe, i) => (
					<Fragment key={`pres-during-response-${i}`}>{pe.element}</Fragment>
				))}
			<div
				style={{
					textAlign: 'center',
					display: 'flex',
					justifyContent: 'space-around',
					marginTop: '70px'
				}}
			>
				{choiceElements}
			</div>
		</>
	);
};
