/**
 * @param hsb
 */
export const hsbToRgb = (hsb: { h: number; s: number; b: number }) => {
	const rgb: { r: number; g: number; b: number } = { r: 0, g: 0, b: 0 };
	let h = Math.round(hsb.h);
	const s = Math.round((hsb.s * 255) / 100);
	const v = Math.round((hsb.b * 255) / 100);

	if (s === 0) {
		rgb.r = v;
		rgb.g = v;
		rgb.b = v;
	} else {
		const t1 = v;
		const t2 = ((255 - s) * v) / 255;
		const t3 = ((t1 - t2) * (h % 60)) / 60;

		if (h === 360) h = 0;

		if (h < 60) {
			rgb.r = t1;
			rgb.b = t2;
			rgb.g = t2 + t3;
		} else if (h < 120) {
			rgb.g = t1;
			rgb.b = t2;
			rgb.r = t1 - t3;
		} else if (h < 180) {
			rgb.g = t1;
			rgb.r = t2;
			rgb.b = t2 + t3;
		} else if (h < 240) {
			rgb.b = t1;
			rgb.r = t2;
			rgb.g = t1 - t3;
		} else if (h < 300) {
			rgb.b = t1;
			rgb.g = t2;
			rgb.r = t2 + t3;
		} else if (h < 360) {
			rgb.r = t1;
			rgb.g = t2;
			rgb.b = t1 - t3;
		} else {
			rgb.r = 0;
			rgb.g = 0;
			rgb.b = 0;
		}
	}

	return { r: Math.round(rgb.r), g: Math.round(rgb.g), b: Math.round(rgb.b) };
};

export const generateParticipantCode = () => {
	const chars = '123456789ABCDEFGHIJKLMNPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
	const string_length = 6;
	let c_code = '';
	for (let i = 0; i < string_length; i++) {
		const rnum = Math.floor(Math.random() * chars.length);
		c_code += chars.substring(rnum, rnum + 1);
	}
	return c_code;
};
