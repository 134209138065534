import { FC, HTMLProps } from 'react';
import { MaterializeColor } from 'types';
import { defaults } from 'utility';

interface Props extends HTMLProps<HTMLButtonElement> {
	color?: MaterializeColor;
	showIf?: boolean;
	zDepth?: 0 | 1 | 2 | 3 | 4 | 5;
}

/**
 * My own button
 */
export const Button: FC<Props> = ({ children, onClick, color, showIf, zDepth, style }) => {
	if (showIf === false) return <></>;

	return (
		<button
			className={`btn ${color ?? defaults.theme.colorPrimary} z-depth-${zDepth ?? 0} waves-effect waves-light`}
			style={style}
			onClick={onClick}
		>
			{children}
		</button>
	);
};
