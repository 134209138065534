import { FC } from 'react';
import { Navbar } from 'components';
import { Header } from 'ui';

export const Home: FC = () => (
	<>
		<Navbar />
		<div style={{ margin: '50px 20%' }}>
			<Header>Welcome to Lang</Header>
		</div>
	</>
);
